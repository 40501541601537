/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { pageActions } from '../../../Helpers/Stores/pageStore';
import Button from './Elements/Button';
import ViewMenu from './Layouts/ViewMenu';
import ReactGridLayout, { WidthProvider } from 'react-grid-layout';
import { IGenericPageEditorProps, IContainerLayout, IContainer } from '../../../Helpers/Interfaces';
import loadJsonPost from '../Utils/jsonPost';
import loadJsonData from '../Utils/jsonGet';
import LocalStorage from '../../../Helpers/MFXLocalStorage';
import IconMenu from './Layouts/IconMenu';
import Editbutton from './Elements/Editbutton';
import loadJsonPut from '../Utils/jsonPut';
import CreatableSelect from 'react-select/creatable';
import DefaultLabels from '../../../Data/designer/default_labels.json';
import URLS from '../Utils/URLs.API';
import { Link } from 'react-router-dom';
import localStorageLink from '../../../Helpers/LocalStorageLink';
import SavedModal from './Elements/SavedModal';
import menu from '../../../Data/Designer/menu_main.json';
import loadJsonDelete from '../Utils/jsonDelete';
import MainHeadline from '../Elements/MainHeadline';
import { useSearchParams } from 'react-router-dom';

/* --------------- IMPORT ALL FILES from FOLDER ------------- */

function importAll(r: any) {
    let data = {};
    r.keys().forEach((item: any) => {
        (data as any)[item.replace('./', '').replace(/\.(png|ico|svg|jpg|gif|json)$/, '')] = r(item);
    });
    return data;
}

/* --------------- IMPORT ALL IMAGES ------------- */
const images = importAll((require as any).context('../../../Styles/images', false, /\.(png|ico|svg|jpg|gif)$/));

/* --------------- IMPORT ALL Pages ------------- */
const defaultPages = importAll((require as any).context('../../../Data/Designer/Defaultpages', false, /\.(json)$/));

const GridLayout = WidthProvider(ReactGridLayout);
type Settingstype = 'default' | 'Icon' | 'Headline' | 'views';

interface IPage {
    ID: number;
    Type: string;
    ConfigData: string;
    PageLayout: string;
    BusinessProcessName: string;
    PageName: string;
}

export function GenericPageEditor(props: IGenericPageEditorProps) {
    const pageData = useSelector((state: RootState) => state.pageData);
    const pageID = useSelector((state: RootState) => state.pageID);
    const [showPreviews, setShowPreviews] = useState(false);
    const dispatch = useDispatch();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [curPageLayout, setCurPageLayout] = useState({
        selected: { label: useSearchParams()[0].get('layoutName') ?? '', value: useSearchParams()[0].get('layoutName') ?? '' },
        layouts: [] as any,
        new: '',
        mobile: false,
    });
    const [saveMessage, setSaveMessage] = useState(null as any);
    //needed, because Layout doesn't allow to save more Data
    const [curContainerLayouts, setCurContainerLayouts] = useState([] as Array<IContainerLayout>);

    const [curPage, setCurPage] = useState({
        selected: false,
        saved: false,
        containerId: -1,
    });

    const [settingsSelected, setSettingsSelected] = useState({
        default: true,
        Icon: false,
        Headline: false,
        views: false,
    });
    useEffect(() => {
        if (curPageLayout?.selected) {
            var newurl = window.location.href.split('?')[0] + '?layoutName=' + curPageLayout?.selected.value;
            window.history.pushState({ path: newurl }, '', encodeURI(newurl));
        }
    }, [curPageLayout?.selected]);
    //#region UseEffects
    useEffect(() => {
        

        if (!curPageLayout.new) {
            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetBusinessProcessPage + '?PageName=' + props.name + '&PageLayout=' + curPageLayout.selected.value) //'&&PageLayout=default&BusinessProcessName=CRM')
                .then((data: any) => {
                    if (data.Data != null && data.Data.Json !== null && data.Data.length !== 0) {
                        dispatch(pageActions.init({ json: data.Data.Json, id: data.Data.ID }));
                        setDataLoaded(true);
                        let curdata = data.Data.Json;
                        setCurPage({ selected: false, saved: true, containerId: -1});

                        if (curdata.length) {
                            //Create Layout for React Gridlayout
                            let Containers: Array<IContainerLayout> = [];
                            for (let i = 0; i < curdata.length; i++) Containers.push({ i: curdata[i].name, x: curdata[i].x, y: curdata[i].y, w: curdata[i].w, h: curdata[i].h ?? 1 });
                            setCurContainerLayouts(Containers as Array<IContainerLayout>);
                        }
                    } else {
                        //------ NEW EMPTY PAGE
                        if (data.Data && data.Data.ID) dispatch(pageActions.init({ json: [], id: data.Data.ID })); //when new page in Database without ConfigData
                        else dispatch(pageActions.init({ json: [], id: -1 }));
                        setCurContainerLayouts([]);
                        setDataLoaded(true);
                    }
                })
                .catch(e => {});
        } else {
            console.log(props.name, curPageLayout);
        }
    }, [dispatch, setCurPageLayout, setCurContainerLayouts, props.name, curPageLayout.selected]);

    useEffect(() => {


        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetPageLayouts + '?PageName=' + props.name).then((data: any) => {
            if (data.Meta.Success) {
                let labelValue = data.Data.find((d: any) => d?.PageLayout === curPageLayout?.selected?.value) ? curPageLayout.selected.value : data.Data.find((d: any) => d?.PageLayout === 'default') ? 'default' : data.Data[0]?.PageLayout ?? 'default';
                setCurPageLayout({ selected: { label: labelValue, value: labelValue }, layouts: data.Data, new: '', mobile: curPageLayout.selected.value.includes('-mobile') });
            } else {
                console.log('es wurden keine Layouts gefunden');
            }
        });
    }, [props.name]);

    //#endregion UseEffects
    //#region Container Functions
    function resetContainerData() {
        console.log('reset');
        dispatch(pageActions.resetData());
        setCurPage({ ...curPage, selected: false, saved: true, containerId: -1 });
    }

    function deleteContainer(containerindex: number) {
        if (pageData.length === 1) setCurContainerLayouts([]);
        else {
            let layoutContainer = [...curContainerLayouts];
            layoutContainer.splice(containerindex, 1);
            setCurContainerLayouts(layoutContainer);
        }

        selectContainerByIndex(-1, true);
        dispatch(pageActions.deleteContainer({ containerindex: containerindex }));
        let curData = [...pageData];
        curData.splice(containerindex, 1);
        //saveContainerData();
    }

    function selectContainerByIndex(index: number, clear = false) {
        setCurPage({ ...curPage, containerId: index, selected: !clear });
    }

    function addViewToContainer(settingsObject: any) {
        dispatch(pageActions.changeCheckedViews({ ...settingsObject, curSelected: curPage.containerId }));
    }

    function generateContainerName() {
        return 'container-' + props.name + (curContainerLayouts.length > 0 ? +curContainerLayouts.sort((a: any, b: any) => (+a.i.replace('container-' + props.name, '') < +b.i.replace('container-' + props.name, '') ? 1 : -1))[0]?.i.replace('container-' + props.name, '') + 1 : '1');
    }

    function addContainer() {
        let containers = curContainerLayouts;
        let lastRow = 0;
        let containername = '';
        let nameFound = false;
        containername = generateContainerName();
        containers.forEach((container: IContainerLayout) => {
            if (containername === container.i) {
                nameFound = true;
                return;
            }
            if (container.h + container.y > lastRow) {
                lastRow = container.h + container.y;
            }
        });

        let newContainer = {
            name: containername,
            Headline: '',
            Subheadline: '',
            Icon: '',
            label: '',
            i: containername,
            h: 1,
            w: curPageLayout.mobile ? 1 : 4,
            x: 0,
            y: lastRow + 1,
            children: {},
            index: pageData.length === -1 ? 1 : pageData.length,
        };

        setCurContainerLayouts([...curContainerLayouts, newContainer]);
        dispatch(pageActions.addContainer(newContainer));
        selectContainerByIndex(pageData.length === -1 ? 0 : pageData.length);
        setCurPage({ ...curPage, saved: false });
    }

    function editContainer(e: React.MouseEvent<HTMLElement>, save: boolean = false, containerindex: number) {
        let container = (e.target as HTMLElement).closest('.mfx-gp-container') as HTMLElement;

        //alle Daten speichern
        if (save) saveContainerData();
        if (container.classList.contains('active')) {
            setCurPage({ ...curPage, selected: false, saved: false, containerId: -1 });
        } else {
            if (curPage.selected && !curPage.saved) {
                if (window.confirm('Änderungen werden nicht gespeichert')) setCurPage({ ...curPage, selected: true, saved: false, containerId: containerindex });
            } else {
                setCurPage({ ...curPage, selected: true, saved: false, containerId: containerindex });
            }
        }
    }
    //#endregion Container Functions
    //#region Layout Functions
    const createNewLayout = (inputValue: string) => {
        dispatch(pageActions.init({ json: [], id: -1 }));
        setCurContainerLayouts([]);
        setCurPageLayout({ ...curPageLayout, selected: { value: inputValue, label: inputValue }, new: '(neu)', mobile: inputValue.includes('-mobile') });
        setCurPage({ selected: false, saved: true, containerId: -1});
    };

    function mobileSwitch(inputValue){
        dispatch(pageActions.init({ json: pageData, id: -1 }));
        setCurPageLayout({ ...curPageLayout, selected: { value: inputValue, label: inputValue }, new: '(neu)', mobile: inputValue.includes('-mobile') });
        setCurPage({ selected: false, saved: false, containerId: -1});
    }

    const setPageLayout = (data: any) => {
        dispatch(pageActions.init({ json: data, id: pageID }));
        setCurPage({ selected: false, saved: true, containerId: -1 });

        if (data.length) {
            //Create Layout for React Gridlayout
            let Containers: Array<IContainerLayout> = [];
            for (let i = 0; i < data.length; i++) Containers.push({ i: data[i].name, x: data[i].x, y: data[i].y, w: data[i].w, h: 1 });
            setCurContainerLayouts(Containers as Array<IContainerLayout>);
        }
    };

    function changeLayout(layout: any) {
        dispatch(pageActions.changeLayout({ layout: layout }));
        setCurPage({ ...curPage, saved: false });
    }
    //#endregion Layout Functions
    //#region Button Functions
    const loadDefaultContainer = () => {
        let curPageName = props.name;
        if (curPageName !== undefined) {
            setPageLayout((defaultPages as any)[curPageName]);
        } else {
            alert('Default Data not found');
        }
    };

    const deletePage = () => {
        console.log(curPageLayout, pageID);
        let oldLayout = curPageLayout.selected.value;

        if (curPageLayout.selected.value === 'default') setSaveMessage({ message: 14622, success: false });
        else {
            loadJsonDelete('/' + LocalStorage?.getConnectionKey() + URLS.DeleteBusinessProcessPage + '?Index=' + pageID).then(data => {
                if (data.Meta.Success) {
                    setSaveMessage({ message: 14623, success: true });
                    setCurPageLayout({ ...curPageLayout, new: '', mobile: false });

                    let pageLayouts = [...curPageLayout.layouts];

                    for (let i = 0; i < pageLayouts.length; i++) {
                        if ((pageLayouts as any).PageLayout === oldLayout) {
                            pageLayouts.splice(i, 1);
                            break;
                        }
                    }
                } else setSaveMessage({ message: 14624, success: false });
            });
        }
    };

    function saveContainerData() {
        console.log('save');
        if (pageID && pageID >= 0)
            loadJsonPut('/' + LocalStorage?.getConnectionKey() + URLS.PutBusinessProcessPage, { UpdateData: { ConfigData: JSON.stringify(pageData), BusinessProcessName: 'CRM', PageName: props.name, ID: pageID, PageLayout: curPageLayout.selected.value } }).then(data => {
                dispatch(pageActions.saveData());

                if (data.Meta.Success) setSaveMessage({ message: 14445, success: true });
                else setSaveMessage({ message: 13656, success: false });
            });
        else
            loadJsonPost('/' + LocalStorage?.getConnectionKey() + URLS.PostBusinessProcessPage, { CreateData: { ConfigData: JSON.stringify(pageData), BusinessProcessName: 'CRM', PageName: props.name, PageLayout: curPageLayout.selected.value } }).then(data => {
                if (data.Meta.Success) {
                    dispatch(pageActions.saveData());
                    dispatch(pageActions.setID({ id: (data as any).CreatedData.ID }));

                    setSaveMessage({ message: 15165, success: true });
                } else setSaveMessage({ message: 13656, success: false });
            });

        setCurPage({ ...curPage, selected: false, saved: true, containerId: -1 });
    }
    //#endregion Button Functions
    //#region Misc Functions
    function getLink(key: any) {
        var link = '';
        menu.entries.forEach((e: any) => {
            e.children.forEach(e2 => {
                if (e2.link.split('?')[0].split('/')[2] === key.viewType) {
                    link = e2.link + '&layoutName=' + key.layoutName;
                }
            });
        });
        return link;
    }

    function changeOrder(event: any, directionUp: boolean) {
        let listElement = event.target.closest('li').getAttribute('data-name');
        dispatch(pageActions.changeOrder({ curContainerSelected: curPage.containerId, listElement: listElement, directionUp: directionUp }));
    }

    function showSettings(settingsname: Settingstype) {
        let newSettingsObject = { ...settingsSelected };
        Object.keys(newSettingsObject).forEach(settings => {
            if (settings === settingsname) newSettingsObject[settingsname] = !newSettingsObject[settingsname];
            else newSettingsObject[settings as Settingstype] = false;
        });
        setSettingsSelected(newSettingsObject);
    }
    //#endregion Misc Functions
    return (
        <Provider store={store}>
            <main id="mfx-generic-view-editor">
                {dataLoaded && (
                    <div className="mfx-preview">
                        <div id="mfx-generic-view-preview" className="mfx-gp-page">
                            <h1>
                                {props.menuData && window.Dictionary.getTranslation(props.menuData.label)} <span>(ID: {pageID})</span>
                            </h1>
                            <div id="mfx-grid-layout" className={`${curPageLayout.mobile ? 'mobile' : 'desktop'}`}>
                                {curContainerLayouts.length > 0 && (
                                    <GridLayout
                                        className="layout"
                                        //draggableCancel=".container-content"
                                        layout={curContainerLayouts as any}
                                        rowHeight={500}
                                        onLayoutChange={changeLayout}
                                        cols={curPageLayout.mobile ? 1 : 12}>
                                        {pageData.map((container, idx) => {
                                            return (
                                                <div id={`container-${idx}`} className={`mfx-gp-container container-designer ${curPage.containerId === container.index ? 'active' : ''}`} key={container.name}>
                                                    <div className="container-content">
                                                        <div className="mfx-action-buttons">
                                                            <Editbutton disableChangeHandler={(e: any, save: boolean) => editContainer(e, save, idx)} deleteContainer={() => deleteContainer(idx)} resetChanges={resetContainerData} disableMode={curPage.containerId === idx ? false : true} />
                                                        </div>
                                                        <div className="mfx-element-headline headline-main">
                                                            <MainHeadline designData={{ Subheadline: container.Subheadline ?? '', Headline: container.Headline ?? '', Description: container.Description ?? '', IconColor: container.IconColor ?? '', Icon: container.Icon ?? '' }} />
                                                        </div>

                                                        <h3>
                                                            {window.Dictionary.getTranslation(14895)}: {curPage.containerId === container.index && pageData[curPage.containerId]?.Group && <span>({pageData[curPage.containerId]?.Group})</span>}
                                                        </h3>
                                                        <ul className="view-list">
                                                            {Object.entries(container.children).map((key: any, index) => {
                                                                return (
                                                                    <li key={index} className="view-listitem" data-name={key[1].viewType + '-' + key[1].layoutName} style={{ order: key[1]?.order }}>
                                                                        <div>
                                                                            <div>
                                                                                <div className="view-listitem-maininfo">
                                                                                    <span>{key[1]?.order}</span>
                                                                                    <Link to={localStorageLink(getLink(key[1]))}>
                                                                                        <span>
                                                                                            {key[1].viewType} ({key[1].layoutName})
                                                                                        </span>
                                                                                    </Link>
                                                                                </div>
                                                                                {curPage.selected && (pageData[curPage.containerId] as IContainer)?.name === container.name && pageData[curPage.containerId]?.Group && (
                                                                                    <label>
                                                                                        Tabname / Accordionname
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Tabname / Accordionname"
                                                                                            defaultValue={key[1]?.ViewLabel}
                                                                                            onChange={e => dispatch(pageActions.changeTabLabel({ containerindex: curPage.containerId, view: key[1].viewType + '-' + key[1].layoutName, newValue: e.target.value }))}
                                                                                        />
                                                                                    </label>
                                                                                )}
                                                                            </div>
                                                                            {curPage.selected && (pageData[curPage.containerId] as IContainer)?.name === container.name && (
                                                                                <div className="container-view-icons">
                                                                                    <button className="icon-arrow-up" onClick={e => changeOrder(e, true)}></button>
                                                                                    <button className="icon-arrow-down" onClick={e => changeOrder(e, false)}></button>
                                                                                    <button className="icon-delete" onClick={() => dispatch(pageActions.deleteView({ containerindex: curPage.containerId, view: key[1].viewType + '-' + key[1].layoutName }))}></button>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {showPreviews && (images as any)[key[1].viewType] && (
                                                                            <div className="view-listitem-preview">
                                                                                <img alt="" src={(images as any)[key[1].viewType]} />
                                                                            </div>
                                                                        )}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </GridLayout>
                                )}
                            </div>

                            <div className="grid-common-buttons">
                                <button className={`icon-eye showpreview-${showPreviews}`} onClick={e => setShowPreviews(!showPreviews)}></button>
                                <Button onClickHandler={loadDefaultContainer} iconClass="fa-solid fa-rotate-right" dll={14625} />
                                <Button onClickHandler={addContainer} iconClass="icon-add" dll={14626} />
                                {!curPage.saved && <Button onClickHandler={() => saveContainerData()} important={true} iconClass="icon-save" dll={63} />}
                            </div>
                        </div>
                        <div className="mfx-sidebar">
                            <h2>{window.Dictionary.getTranslation(1109)}</h2>

                            {/* ------------------- LAYOUT ------------------ 
                                        Select or create a Layout
                                --------------------------------------------------*/}
                            {
                                <>
                                    {curPageLayout.new && curPageLayout.new}
                                    <div className="layout-name-id">
                                        <div className='mfx-react-select'>
                                            <CreatableSelect
                                                onChange={(newValue: any) => {
                                                    setCurPageLayout({ ...curPageLayout, selected: { label: newValue.value, value: newValue.value }, new: '', mobile: newValue.value.includes('-mobile') });
                                                }}
                                                onCreateOption={createNewLayout}
                                                options={(curPageLayout.layouts as any)?.map((layout: any) => {
                                                    return { label: layout.PageLayout, value: layout.PageLayout };
                                                })}
                                                value={curPageLayout.selected}
                                            />
                                        </div>
                                    </div>
                                    <div className="grid-common-buttons">
                                        {/* --------------------- MOBILE ------------------ 
                                                        Add or Edit a mobile Layout 
                                        --------------------------------------------------*/}

                                        <div className="mfx-checkbox-element button-checkbox">
                                            <input
                                                type="checkbox"
                                                checked={curPageLayout.mobile}
                                                id="mobile-page-checkbox"
                                                onChange={(e: any) => {
                                                    if (e.target.checked) {
                                                        if(curPageLayout.layouts.find(layout => layout.PageLayout === curPageLayout.selected.value + '-mobile')){
                                                            setCurPageLayout({ ...curPageLayout, selected: { label: curPageLayout.selected.value + '-mobile', value: curPageLayout.selected.value + '-mobile' }, new: '', mobile: true });
                                                        }else{
                                                            mobileSwitch(curPageLayout.selected.value + '-mobile');
                                                        }                                                    
                                                    } else {
                                                        let curlayoutName = curPageLayout.selected.value.replace('-mobile', '');
                                                        if(curPageLayout.layouts.find(layout => layout.PageLayout === curlayoutName)){
                                                            setCurPageLayout({ ...curPageLayout, selected: { label: curlayoutName, value: curlayoutName }, new: '', mobile: false });
                                                        }else{
                                                            mobileSwitch(curlayoutName);
                                                        }
                                                    }
                                                }}
                                            />
                                            <label htmlFor="mobile-page-checkbox" className="mfx-button mfx-button-type-icon save-button-designer ">
                                                <i className="icon-mobile"></i> {window.Dictionary.getTranslation(860)}
                                            </label>
                                        </div>
                                        <Button onClickHandler={deletePage} iconClass="icon-delete" dll={1625} />
                                    </div>
                                </>
                            }
                            {curPage.containerId >= 0 && (
                                <>
                                    <div className="mfx-settings-accordion">
                                        <h3 className={settingsSelected.default ? 'active' : ''} onClick={() => showSettings('default')}>
                                            {window.Dictionary.getTranslation(2508)}
                                        </h3>
                                        {settingsSelected.default && (
                                            <>
                                                <h5>{window.Dictionary.getTranslation(14867)}</h5>
                                                {
                                                    <div className={window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'mfx-react-select-dark page-headline-container' : 'page-headline-container'}>
                                                        <CreatableSelect
                                                            onChange={(newValue: any) => dispatch(pageActions.changeData({ type: 'Headline', value: newValue.value, curSelected: curPage.containerId }))}
                                                            //onCreateOption={createNewLayout}
                                                            options={(DefaultLabels as any)?.map((dll: number) => {
                                                                return { label: window.Dictionary.getTranslation(dll), value: dll };
                                                            })}
                                                            value={(pageData[curPage.containerId] as IContainer).Headline ? { value: (pageData[curPage.containerId] as IContainer).Headline, label: window.Dictionary.getTranslation((pageData[curPage.containerId] as IContainer).Headline) } : ''}
                                                        />
                                                        <i className="icon-delete mfx-button" onClick={() => dispatch(pageActions.removeHeadline({ containerid: curPage.containerId }))}></i>
                                                    </div>
                                                }
                                                <h5>{window.Dictionary.getTranslation(14868)}</h5>
                                                {
                                                    <div className={window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'mfx-react-select-dark page-headline-container' : 'page-headline-container'}>
                                                        <CreatableSelect
                                                            onChange={(newValue: any) => dispatch(pageActions.changeData({ type: 'Subheadline', value: newValue.value, curSelected: curPage.containerId }))}
                                                            //onCreateOption={createNewLayout}
                                                            options={(DefaultLabels as any)?.map((dll: number) => {
                                                                return { label: window.Dictionary.getTranslation(dll), value: dll };
                                                            })}
                                                            value={
                                                                (pageData[curPage.containerId] as IContainer).Subheadline ? { value: (pageData[curPage.containerId] as IContainer).Subheadline, label: window.Dictionary.getTranslation((pageData[curPage.containerId] as IContainer).Subheadline) } : ''
                                                            }
                                                        />
                                                        <i className="icon-delete mfx-button" onClick={() => dispatch(pageActions.removeSubheadline({ containerid: curPage.containerId }))}></i>
                                                    </div>
                                                }
                                                <h5>{window.Dictionary.getTranslation(125)}</h5>
                                                {
                                                    <div>
                                                        <textarea
                                                            onChange={(newValue: any) => {
                                                                dispatch(pageActions.changeData({ type: 'Description', value: newValue.target.value, curSelected: curPage.containerId }));
                                                            }}
                                                            //value={(pageData[curPage.containerId] as IContainer).Description ? { value: (pageData[curPage.containerId] as IContainer).Description, label: (pageData[curPage.containerId] as IContainer).Description} : ''}
                                                            value={(pageData[curPage.containerId] as IContainer).Description}></textarea>
                                                    </div>
                                                }
                                            </>
                                        )}
                                    </div>
                                    <div className="mfx-settings-accordion">
                                        <h3 className={settingsSelected.views ? 'active' : ''} onClick={() => showSettings('views')}>
                                            {window.Dictionary.getTranslation(3847)}
                                        </h3>

                                        {settingsSelected.views && (
                                            <>
                                                <div className="mfx-checkbox-element button-checkbox">
                                                    <input type="checkbox" id="checktabs" onClick={e => dispatch(pageActions.changeInGroup({ groupType: 'Tabs', value: e.target, curSelected: curPage.containerId }))} checked={pageData[curPage.containerId].Group !== 'Tabs' ? false : true} />
                                                    <label htmlFor="checktabs" className="mfx-button">
                                                        Tabs
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        id="checkaccordion"
                                                        onClick={e => dispatch(pageActions.changeInGroup({ groupType: 'Accordion', value: e.target, curSelected: curPage.containerId }))}
                                                        checked={pageData[curPage.containerId].Group !== 'Accordion' ? false : true}
                                                    />
                                                    <label htmlFor="checkaccordion" className="mfx-button">
                                                        Accordion
                                                    </label>
                                                </div>
                                                <ViewMenu label="Views" data={pageData[curPage.containerId] as IContainer} onChangeHandler={settingsObject => addViewToContainer(settingsObject)} />
                                            </>
                                        )}
                                    </div>
                                    <div className="mfx-settings-accordion">
                                        <h3 className={settingsSelected.Icon ? 'active' : ''} onClick={() => showSettings('Icon')}>
                                            {window.Dictionary.getTranslation(15222)}
                                        </h3>
                                        {settingsSelected.Icon && (
                                            <IconMenu
                                                curContainer={pageData[curPage.containerId] as IContainer}
                                                changeIcon={(data: any) => {
                                                    dispatch(pageActions.changeData({ ...data, curSelected: curPage.containerId }));
                                                }}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {saveMessage && <SavedModal saveMessage={saveMessage} closeIcon={true} handleClose={() => setSaveMessage(null)} />}
                    </div>
                )}
            </main>
        </Provider>
    );
}

export function GenericPageProvider(props: IGenericPageEditorProps) {
    return (
        <Provider store={store}>
            <GenericPageEditor {...props} />
        </Provider>
    );
}

export type RootState = ReturnType<typeof store.getState>;
