//IMPORT HERE
import TableView from './Views/TableView';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store, { viewActions } from '../../../Helpers/Stores/viewStore';
import { useEffect, useState } from 'react';
import Button from './Elements/Button';
import ElementsView from './Views/ElementsView';
import TableMenu from './Views/TableMenu';
import ElementsMenu from './Views/ElementsMenu';
import loadJsonPost from '../Utils/jsonPost';
import LocalStorage from '../../../Helpers/MFXLocalStorage';
import loadJsonData from '../Utils/jsonGet';
import loadJsonPut from '../Utils/jsonPut';
import CreatableSelect from 'react-select/creatable';
import URLS from '../Utils/URLs.API';
import DashboardMenu from './Views/DashboardMenu';
import DashboardView from './Views/DashboardView';
import loadJsonDelete from '../Utils/jsonDelete';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import SavedModal from './Elements/SavedModal';
import FreeElementsMenu from './Menu/FreeElementsMenu';
import Info from '../Elements/Info';
import Loading from '../Elements/Loading';
import task from '../../../Data/Designer/Defaultviews/systeminfo.json';

interface IGenericViewEditorProps {
    name: string | undefined;
    menuData: any;
}
/* --------------- IMPORT ALL FILES from Detailviews ------------- */

function importAll(r: any) {
    let data = {};
    r.keys().forEach((item: any) => {
        (data as any)[item.replace('./', '').replace(/\.(json)$/, '')] = r(item);
    });
    return data;
}
const defaultViews = importAll((require as any).context('../../../Data/Designer/Defaultviews', false, /\.(json)$/));

export default function GenericViewEditor(props: IGenericViewEditorProps) {
    const viewData = useSelector((state: RootState) => state.viewData);
    const dispatch = useDispatch();

    const [dataLoaded, setDataLoaded] = useState(false);
    const [curElement, setCurElement] = useState(null);
    const [curFilter, setCurFilter] = useState(null);
    const [layoutNames, setLayoutNames] = useState(null as any);
    const [curLayoutName, setCurLayoutName] = useState(useSearchParams()[0].get('layoutName') ?? '');
    const [dashboardData, setDashboardData] = useState(null as any);
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get('type');
    const [saveMessage, setSaveMessage] = useState(null as any);

    const handleCreate = (inputValue: string) => {
        setLayoutNames((prev: any) => [...prev, { LayoutName: inputValue }]);
        setCurLayoutName(inputValue);
        //setUrlParams('layoutName', inputValue);
    };
    //#endregion Select Layout
    useEffect(() => {
        dispatch(viewActions.setSetting({ key: 'ViewType', value: props.name }));
        loadLayoutNames();
    }, [dispatch, props.name]);

    useEffect(() => {
        if (curLayoutName !== null && layoutNames) {
            loadData(curLayoutName);
        }
    }, [curLayoutName, layoutNames]);

    useEffect(() => {
        if (curLayoutName) {
            var newurl = window.location.href.split('?')[0] + ('?' + window.location.href.split('?')[1].split('&layoutName')[0] + '&layoutName=' + curLayoutName);
            window.history.pushState({ path: newurl }, '', encodeURI(newurl));
        }
    }, [curLayoutName]);

    function loadDataOld() {
        let curData: any = null;

        if (type) {
            curData = { ...(defaultViews as any)[type] };
            console.log(defaultViews);
            console.log(curData);
            if(curData.Data && curData.Data[0]?.CustomerData?.FillData === true){
                curData.Data[0].CustomerData = {...JSON.parse(JSON.stringify({...defaultViews['customer']?.Data[0]}).replaceAll('"Key":"', '"Key":"'+curData.Data[0].CustomerData.Key + '-'))};
            }
            if(curData.Data && curData.Data[0]?.Customer?.FillData === true){
                curData.Data[0].Customer = {...JSON.parse(JSON.stringify({...defaultViews['customer']?.Data[0]}).replaceAll('"Key":"', '"Key":"'+curData.Data[0].Customer.Key + '-'))};
            }
            console.log(curData);
        }

        delete curData?.AddElements;
        delete curData?.DetailElements;
        if (viewData.ViewType === 'Table') {
            delete curData?.CustomElements;
        } else if (viewData.ViewType === 'Custom') {
            delete curData?.Filter;
            delete curData?.TableElements;
        }
        curData.ID = viewData?.ID ?? null;
        curData.ViewLabel = props.name;
        curData.ViewType = viewData.ViewType ?? '';
        return curData;
    }

    function resetViewData() {
        setCurElement(null);
        setCurFilter(null);
        loadData(curLayoutName);
    }

    //#region Table Methods
    function selectFilter(e: any, idx: any) {
        setCurFilter(idx);
    }
    function addFilter() {
        selectFilter(null, (viewData.Filter as any)?.Data?.length ?? 0);
        dispatch(viewActions.addFilter());
    }
    //#endregion Table Methods
    //#region Element Methods
    function selectElement(e: any, key: any) {
        if (e !== null) {
            e.cancelBubble = true;
            if (e.stopPropagation) e.stopPropagation();
        }
        setCurElement(key);
    }
    //#endregion Element Methods

    //#region API Calls
    function loadLayoutNames() {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + '/api/Layout/GetBusinessProcessViewLayoutNames?ViewName=' + props.name).then((data: any) => {
            setLayoutNames(data.Data);
            if (data.Data.findIndex((l: any) => l.LayoutName === curLayoutName) === -1) {
                const idx = data.Data.findIndex((l: any) => l.LayoutName === 'default');
                setCurLayoutName(data.Data[idx > 0 ? idx : 0]?.LayoutName);
            }
        });
    }

    function loadData(layoutName: any) {
        setDataLoaded(false);
        loadJsonData('/' + LocalStorage?.getConnectionKey() + '/api/Layout/GetBusinessProcessView?ViewName=' + props.name + '&LayoutName=' + layoutName)
            .then((data: any) => {
                let id = (data?.Data?.LayoutName && layoutName) && layoutName === data?.Data?.LayoutName ? data.Data.ID : null;
                if (data.Data && props.name === 'DashboardView') {

                    loadDashboardData(layoutName);
                    dispatch(viewActions.init({ DashboardElements: [...data.Data.Json], ID: id, ViewType: data.Data.ViewType, ViewLabel: data.Data.ViewLabel }));
                } else if (data.Data && data.Data.ViewType !== 'Free') {
                    const oldData = loadDataOld();
                    if (data.Data.length !== 0 && data.Data.Json !== null) {
                        if (data.Data.Json.Tablehead) {
                            console.log('STILL OLD TABLEHEAD');
                            data.Data.Json.TableElements = data.Data.Json.Tablehead;
                            delete data.Data.Json.Tablehead;
                        } else if (data.Data.Json.Elements) {
                            console.log('STILL OLD Elements');
                            data.Data.Json.CustomElements = data.Data.Json.Elements;
                            delete data.Data.Json.Elements;
                        }
                        data.Data.Json.Data = oldData.Data;
                        dispatch(viewActions.init({ ...data.Data.Json, ID: id, ViewType: data.Data.ViewType, ViewLabel: data.Data.ViewLabel }));
                    } else {
                        dispatch(viewActions.init({ ...oldData, ID: id ?? null, ViewLabel: props.name, ViewType: data.Data.ViewType ?? '' }));
                        setDataLoaded(true);
                    }
                } else {
                    const oldData = loadDataOld();
                    dispatch(viewActions.init({ ...oldData, ID: id, ViewType: data?.Data?.ViewType ?? 'Table', ViewLabel: data?.Data?.ViewLabel ?? props.name, CustomElements: data?.Data?.Json?.CustomElements ?? [] }));
                }
                if (props.name !== 'DashboardView') {
                    setDataLoaded(true);
                }
            })
            .catch(e => {console.log(e);});
    }
    function loadDashboardData(layoutName: string) {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + '/api/Dashboard/GetDashboard?Viewname=' + props.name + '&LayoutName=' + layoutName).then((data: any) => {
            if (data.Data) {
                setDashboardData(data.Data);
                setDataLoaded(true);
            } else {
                setDashboardData(null);
                setDataLoaded(true);
            }
        });
    }
    function saveViewData() {
        if (curLayoutName === null || curLayoutName === undefined) {
            alert('Bitte Layout angeben/erstellen');
            return;
        }
        let saveData = { ...viewData } as any;
        if (props.name === 'DashboardView') {
            saveData = [...viewData.DashboardElements];
        }
        if (viewData.ViewType === 'Table') {
            saveData.DetailElements && delete saveData.DetailElements;
            saveData.AddElements && delete saveData.AddElements;
            saveData.CustomElements && delete saveData.CustomElements;
        }
        saveData.Data && delete saveData.Data;
        saveData.Meta && delete saveData.Meta;
        saveData.ID && delete saveData.ID;
        saveData.ViewType && delete saveData.ViewType;
        saveData.ViewLabel && delete saveData.ViewLabel;
        if (viewData.ID === null || viewData.ID === undefined) {
            loadJsonPost('/' + LocalStorage?.getConnectionKey() + URLS.PostBusinessProcessView, { CreateData: { ConfigData: JSON.stringify(saveData), LayoutName: curLayoutName, ViewName: props.name, ViewLabel: viewData.ViewLabel, ViewType: viewData.ViewType } }).then(data => {
                if (data.Meta.Success) {
                    setSaveMessage({ message: 14445, success: true });
                    loadData(curLayoutName);
                } else setSaveMessage({ message: 14847, success: false });
            });
        } else {
            loadJsonPut('/' + LocalStorage?.getConnectionKey() + URLS.PutBusinessProcessView, {
                UpdateData: { ConfigData: JSON.stringify(saveData), LayoutName: curLayoutName, ViewName: props.name, ViewLabel: viewData.ViewLabel, ID: viewData.ID, ViewType: viewData.ViewType },
            }).then(data => {
                if (data.Meta.Success) {
                    setSaveMessage({ message: 14445, success: true });
                    loadData(curLayoutName);
                } else setSaveMessage({ message: 14847, success: false });
            });
        }
    }

    function resetViewDataToJson() {
        dispatch(viewActions.init(loadDataOld()));
    }

    function removeView() {
        if (curLayoutName.toLowerCase() === 'default') {
            alert('Default views cannot be deleted!');
            return;
        }
        loadJsonDelete('/' + LocalStorage?.getConnectionKey() + URLS.DeleteBusinessProcessView + '?Index=' + viewData.ID).then(data => {
            if (data.Meta.Success) {
                alert('View (' + props.name + '-' + curLayoutName + ') erfolgreich gelöscht');
                loadLayoutNames();
            }
        });
    }
    //#endregion API Calls
    const [width, setWidth] = useState(85);
    function resizeScreen(e: any) {
        if (e.clientX === 0) return;
        setWidth((e.pageX / window.innerWidth) * 100);
    }

    return (
        <main id="mfx-generic-view-editor">
            {dataLoaded && (
                <>
                    <div className="mfx-preview" style={{ gridTemplateColumns: width + '% 10px auto' }}>
                        <div id="mfx-generic-view-preview" className="mfx-gp-page">
                            <h1>
                                {props.menuData && window.Dictionary.getTranslation(props.menuData.label)} ({curLayoutName})
                            </h1>
                            <div className="mfx-view-preview">
                                <div className="mfx-view">
                                    {viewData.ViewType === 'Table' && <TableView addFilter={addFilter} onSelectFilter={selectFilter} curFilter={curFilter} data={viewData} onSaveHandler={saveViewData} />}
                                    {viewData.ViewType === 'StaticTable' && <TableView data={viewData} onSaveHandler={saveViewData} />}
                                    {(viewData.ViewType === 'Custom' || viewData.ViewType === 'Free') && <ElementsView data={viewData} curElement={curElement} onElementGroupClicked={selectElement} onSelectElement={selectElement} onSaveHandler={saveViewData} />}
                                    {viewData.ViewType === 'Dashboard' && dashboardData && <DashboardView data={dashboardData}></DashboardView>}
                                </div>
                            </div>
                            <div className="grid-common-buttons">
                                <Button onClickHandler={resetViewDataToJson} iconClass="fa-solid fa-rotate-right" dll={351} />
                                <Button onClickHandler={resetViewData} iconClass="icon-close" dll={1472} />
                                <Button onClickHandler={saveViewData} iconClass="icon-save" important={true} dll={63} />
                            </div>
                        </div>
                        <div className="mfx-resizer" draggable={true} onDrag={resizeScreen}></div>
                        <div className="mfx-sidebar">
                            {
                                <>
                                    <h2>
                                        {window.Dictionary.getTranslation(134)} <Info text={window.Dictionary.getTranslation(15407)} />{' '}
                                    </h2>
                                    <div className='mfx-react-select'>
                                        <CreatableSelect
                                            //isDisabled={addLayoutIsLoading}
                                            //isLoading={addLayoutIsLoading}
                                            onChange={(newValue: any) => {
                                                setCurLayoutName(newValue.value);
                                                //setUrlParams('layoutName', newValue.value);
                                            }}
                                            onCreateOption={handleCreate}
                                            options={layoutNames?.map((name: any) => {
                                                return { label: name.LayoutName, value: name.LayoutName };
                                            })}
                                            value={{ label: curLayoutName, value: curLayoutName }}
                                        />
                                    </div>
                                </>
                            }
                            <h4>
                                {window.Dictionary.getTranslation(15224)} <Info text={window.Dictionary.getTranslation(15406)} />
                            </h4>
                            <input type="text" value={viewData.ViewLabel} onChange={e => dispatch(viewActions.setSetting({ key: 'ViewLabel', value: e.target.value }))} />

                            {(viewData.ViewType === 'Table' || viewData.ViewType === 'Dashboard' || viewData.ViewType === 'Custom') && (
                                <>
                                    <button className="mfx-button button-border" onClick={removeView}>
                                        {window.Dictionary.getTranslation(14627)}
                                    </button>
                                    {/*
                                        <button
                                            className="mfx-button button-border"
                                            onClick={e => {
                                                console.log(JSON.stringify(jsonDataHelper(task.Data[0])));
                                            }}>
                                            Json Data Helper
                                        </button> //*/
                                    }
                                        
                                </>
                            )}
                            {viewData.ViewType === 'Table' && <TableMenu type={type} onSelectFilter={selectFilter} defaultSettings={viewData.Settings} curFilter={curFilter as any} filter={viewData.Filter} data={viewData.Data[0]} tablehead={viewData.TableElements} />}
                            {viewData.ViewType === 'StaticTable' && <TableMenu type={type} onSelectFilter={selectFilter} defaultSettings={viewData.Settings} data={viewData.Data[0]} tablehead={viewData.TableElements} />}
                            {viewData.ViewType === 'Custom' && <ElementsMenu data={viewData} index={curElement} onSelectElement={selectElement} onChangeHandler={settingsObject => dispatch(viewActions.changeData(settingsObject))} />}
                            {viewData.ViewType === 'Dashboard' && <DashboardMenu data={viewData}></DashboardMenu>}
                            {viewData.ViewType === 'Free' && <FreeElementsMenu data={viewData} index={curElement} onSelectElement={selectElement} onChangeHandler={settingsObject => dispatch(viewActions.changeData(settingsObject))} />}
                        </div>
                    </div>
                    {saveMessage && <SavedModal saveMessage={saveMessage} closeIcon={true} handleClose={() => setSaveMessage(null)} />}
                </>
            )}
            {!dataLoaded &&
                <Loading/>
            }
        </main>
    );
}

export function GenericViewProvider(props: IGenericViewEditorProps) {
    return (
        <Provider store={store}>
            <GenericViewEditor {...props} />
        </Provider>
    );
}

export type RootState = ReturnType<typeof store.getState>;

// usefull don't delete
function jsonDataHelper(data: any, prevKey: string = '') {
    var obj: any = {};
    Object.entries(data).forEach((x: any) => {
        if (Array.isArray(x[1])) {
            obj[x[0]] = [jsonDataHelper(x[1][0], prevKey ? prevKey + '-' + x[0] : x[0])];
        } else if (typeof x[1] === 'object' && x[1]['Designable'] === undefined) {
            obj[x[0]] = jsonDataHelper(x[1], prevKey ? prevKey + '-' + x[0] : x[0]);
        } else {
            switch (typeof x[1]) {
                case 'boolean':
                    obj[x[0]] = { Designable: true, CheckedInDesigner: false, Label: '', Name: '', Key: prevKey ? prevKey + '-' + x[0] : x[0], Elementtype: 'checkbox', ReadOnly: false, Visible: true, DefaultValue: '', Tooltip: '' };
                    break;
                case 'number':
                    obj[x[0]] = { Designable: true, CheckedInDesigner: false, Label: '', Name: '', Key: prevKey ? prevKey + '-' + x[0] : x[0], Elementtype: 'input', Type: 'number', ReadOnly: false, Visible: true, Defaultvalue: '', Tooltip: '' };
                    break;
                case 'string':
                    obj[x[0]] = { Designable: true, CheckedInDesigner: false, Label: '', Name: '', Key: prevKey ? prevKey + '-' + x[0] : x[0], Elementtype: 'input', Type: x[1] === 'string' ? 'text' : 'date', ReadOnly: false, Visible: true, Defaultvalue: '', Tooltip: '' };
                    break;
                default:
                    obj[x[0]] = x[1].Name ? x[1] : { ...x[1], Name: x[1].Label };
            }
        }
    });
    return obj;
}
