/**
 * Renders a Pie Chart
 * @returns {JSX.Element} The Pie Chart
 */

import { ResponsivePie } from '@nivo/pie';

const PieChart = props => {
    const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
        if (props.chart.Data.length !== 2) return <></>;
        let percent =
            parseFloat(props.chart.Data[0].value) < parseFloat(props.chart.Data[1].value) ? ((100 / parseFloat(props.chart.Data[1].value)) * parseFloat(props.chart.Data[0].value)).toFixed(2) : ((100 / parseFloat(props.chart.Data[0].value)) * parseFloat(props.chart.Data[1].value)).toFixed(2);
            return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '36px',
                    fontWeight: 600,
                    fill: 'var(--fontColor)',
                }}>
                {/*props.chart.Data[1].id*/}
                {percent} %
            </text>
        );
    };
    /*const data = [
            {
            'id': 'sales',
            'label': props.chart.Data[0]?.label,
            'value': props.chart.Data[0]?.value,
            'color': '#516eb2'
            },
            {
            'id': 'missing',
            'label': props.chart.Data[1]?.value + 'negativ',
            'value': props.chart.Data[1]?.value,
            'color': '#263455'
            }
        ];*/

    const data = [
        {
            id: 'sales',
            value: 50,
        },
        {
            id: 'missing',
            value: 80,
        },
    ];

    return (
        <>
            {props.chart?.Data && props.chart.Data?.find(d => parseInt(d.value.replace(',', '.'))) && (
                <>
                    <div className="mfx-chart">
                        <ResponsivePie
                            data={props.chart.Data.map(d => {return {...d, value: parseFloat(d.value.replace(',', '.'))};})}
                            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                            innerRadius={0.85}
                            padAngle={0.7}
                            activeOuterRadiusOffset={8}
                            enableArcLinkLabels={false}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsThickness={2}
                            arcLabel={props.chart.Data?.find(d => d?.label) ? 'label' : 'value'}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            colors={props.chart.Data[0].color ? props.chart.Data.map(d => d.color) : ['var(--statisticcolor1', 'var(--statisticcolor2)', 'var(--statisticcolor3']}
                            arcLabelsTextColor={'var(--fontColor)'}
                            theme={{
                                tooltip: {
                                    container: {
                                        backgroundColor: 'var(--bgColor)',
                                    },
                                },
                            }}
                            
                            tooltip={e=> { return <div style={{
                                padding: 12,
                                background: 'var(--bgColor)'
                              }}>
                                                <strong>
                                                    {e?.datum?.id}: {props.chart.Data?.find(d => d?.label) ? e?.datum?.label : e?.datum?.value}
                                                </strong>
                                            </div>;}}



                            layers={['arcs', 'arcLabels', 'arcLinkLabels', 'legends', CenteredMetric]}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'row',
                                    justify: false,
                                    translateX: 0,
                                    translateY: 56,
                                    itemsSpacing: 0,
                                    itemWidth: 110,
                                    itemHeight: 18,
                                    itemTextColor: '#999',
                                    itemDirection: 'left-to-right',
                                    itemOpacity: 1,
                                    symbolSize: 18,
                                    symbolShape: 'circle',
                                    color: { from: 'color' },
                                },
                            ]}
                        />
                    </div>
                </>
            )}
            {(props.chart?.Data?.length === 0 || !props.chart?.Data?.find(d => parseInt(d.value.replace(',', '.')))) && (
                <h4 className="nothing-found">
                    <div className="success-false extra-small">
                        <div className="icon-style-arrow">
                            <i className="icon-close"></i>
                        </div>
                    </div>
                    {window.Dictionary.getTranslation(5484)}
                </h4>
            )}
        </>
    );
};

export default PieChart;
