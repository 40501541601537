/*
 * Storage for all Customers
 * Customer List
 */

import { createSlice, configureStore, current } from '@reduxjs/toolkit';

const viewSlice = createSlice({
    name: 'view',
    initialState: {
        viewData: {
            ID: null,
            Meta: {},
            Data: [],
            CustomElements: [],
            AddElements: [],
            DetailElements: [],
            TableElements: [],
            DashboardElements: [],
            Filter: {},
            Settings: {},
            data: [],
            Elements: [],
            ViewLabel: '',
            ViewType: '',
        },
    },
    reducers: {
        init(state, action) {
            //sets initial Data
            state.viewData = action.payload;
        },
        //#region Tables
        changeData(state, action) {
            console.log('Change Data');
            let arrayVar = action.payload.key.split('-');
            let newVal = action.payload.newValue;
            if (arrayVar[0] === 'Data') {
                arrayVar.shift();
            }
            let elementtype = action.payload.elementtype === 'checkbox' ? 'checkbox' : action.payload.elementtype === 'currency' ? 'currency' : action.payload.elementtype === 'download' ? 'download' : 'tabletext';
            if (!newVal) delete delete state.viewData.TableElements[0][Object.entries(state.viewData.TableElements[0]).find(d => d[1].Key === action.payload.key)[0]];
            else state.viewData.TableElements[0][action.payload.key] = { Key: action.payload.key, Name: action.payload.label, Label: action.payload.label, Sortable: true, Order: 200, Elementtype: elementtype, Type: action.payload.type ?? 'text' };
            console.log(current(state.viewData));
            fillOrder(state.viewData.TableElements[0]);
        },
        changeTableHead(state, action) {
            // maybe switch this to settings instead of tableElement
            if (action.payload.prevTarget !== '') state.viewData.TableElements[0][action.payload.prevTarget][action.payload.property] = false;
            if (state.viewData.TableElements[0][action.payload.target]) state.viewData.TableElements[0][action.payload.target][action.payload.property] = true;
        },
        changeDefaultSettings(state, action) {
            if (!state.viewData.Settings) {
                state.viewData.Settings = {};
            }
            state.viewData.Settings[action.payload.target] = action.payload.newValue;
        },
        changeSortDescending(state, action) {
            // maybe switch this to settings instead of tableElement
            state.viewData.TableElements[0][action.payload.target]['SortDescending'] = !state.viewData.TableElements[0][action.payload.target]['SortDescending'];
        },
        changeValue(state, action) {
            Object.entries(state.viewData.TableElements[0]).find(e => e[1].Key === action.payload.key)[1][action.payload.target] = action.payload.newValue;
        },
        switchOrder(state, action) {
            console.log(action.payload);
            const newOrder = state.viewData.TableElements[0][action.payload.target].Order;
            state.viewData.TableElements[0][action.payload.shadow].Order = newOrder + (action.payload.insertBefore ? -0.5 : 0.5);
            fillOrder(state.viewData.TableElements[0]);
        },
        setSetting(state, action) {
            state.viewData[action.payload.key] = action.payload.value;
        },

        //#endregion Tables
        //#region Elements
        addElement(state, action) {
            state.viewData.CustomElements[action.payload.dataId].Data[action.payload.elId] = [...state.viewData.CustomElements[action.payload.dataId].Data[action.payload.elId], action.payload.newEl];
        },
        selectElement(state, action) {
            var index = getSelectedKeys(state.viewData.CustomElements).findIndex(el => el === action.payload.key);
            if (index === -1) {
                var newElement = { Elementtype: 'input', Type: action.payload.designData.Type, DefaultValue: '', Label: action.payload.designData.Label, Key: action.payload.key };
                state.viewData.CustomElements[0].Data[0] = [...state.viewData.CustomElements[0].Data[0], newElement];
            } else {
                var x = state.viewData.CustomElements.map(grp => {
                    return {
                        ...grp,
                        Data: grp?.Data.map(col => {
                            return col.filter(row => {
                                if (row.Key === action.payload.key) {
                                    return false;
                                }
                                return true;
                            });
                        }),
                    };
                });
                state.viewData.CustomElements = x;
            }
        },
        removeElement(state, action) {
            state.viewData.CustomElements[action.payload.groupKey].Data[action.payload.colKey].splice(action.payload.rowKey, 1);
        },
        changeElement(state, action) {
            console.log(action.payload);
            let groupkey = action.payload.key.groupKey ?? 0;
            let rowkey = action.payload.key.rowKey ?? 0;
            console.log(current(state.viewData.CustomElements[groupkey].Data[action.payload.key.colKey][rowkey]));

            state.viewData.CustomElements[groupkey].Data[action.payload.key.colKey][rowkey][action.payload.target] = action.payload.value;
            if (state.viewData.CustomElements[groupkey].Data[action.payload.key.colKey][rowkey]?.Key) {
                console.log(current(state.viewData.Data[0]));
                console.log('XXX');
                if (state.viewData.CustomElements[groupkey].Data[action.payload.key.colKey][rowkey]?.Elementtype === 'dropdown' || (action.payload.target === 'Elementtype' && action.payload.value === 'dropdown')) {
                    console.log('y');
                    var data = getDataFromKey(state.viewData.Data[0], state.viewData.CustomElements[groupkey].Data[action.payload.key.colKey][rowkey]?.Key);
                    console.log(current(data));
                    state.viewData.CustomElements[groupkey].Data[action.payload.key.colKey][rowkey].DropdownName = data?.DropdownName ?? '';
                }
            }
            console.log(current(state.viewData.CustomElements[groupkey].Data[action.payload.key.colKey][rowkey]));
        },
        switchElements(state, action) {
            if (action.payload.shadow === action.payload.target) return;
            if (action.payload.shadow.colKey === null || action.payload.shadow.colKey === undefined) {
                switchElementGroup(state, action);
            } else {
                const add = action.payload.target.rowKey > action.payload.shadow.rowKey && action.payload.shadow.colKey === action.payload.target.colKey ? (action.payload.insertBefore ? -1 : 0) : action.payload.insertBefore ? 0 : 1;
                state.viewData.CustomElements[action.payload.target.groupKey].Data[action.payload.target.colKey].splice(
                    +action.payload.target.rowKey + add,
                    0,
                    state.viewData.CustomElements[action.payload.shadow.groupKey].Data[action.payload.shadow.colKey].splice(action.payload.shadow.rowKey, 1)[0]
                );
            }
        },
        //#endregion Elements
        //#region ElementGroups
        changeElementGroup(state, action) {
            if (action.payload.target === 'Groupcol') {
                let cols = 12 / action.payload.value;
                while (state.viewData.CustomElements[action.payload.key.groupKey].Data.length !== cols) {
                    if (state.viewData.CustomElements[action.payload.key.groupKey].Data.length < cols) {
                        state.viewData.CustomElements[action.payload.key.groupKey].Data.push([]);
                    } else if (state.viewData.CustomElements[action.payload.key.groupKey].Data.length > cols) {
                        state.viewData.CustomElements[action.payload.key.groupKey].Data.pop();
                    }
                }
            }
            state.viewData.CustomElements[action.payload.key.groupKey][action.payload.target] = action.payload.value;
        },
        addElementGroup(state) {
            if (!state.viewData?.CustomElements) {
                state.viewData.CustomElements = [];
            }
            state.viewData.CustomElements.push({ Elementtype: 'elementgroup', Grouptype: 'text', Groupcol: 12, Data: [[]] });
        },
        removeElementGroup(state, action) {
            state.viewData.CustomElements.splice(action.payload, 1);
        },
        //#endregion ElementGroup
        //#region Filters
        changeFilterColumns(state, action) {
            state.viewData.Filter.Col = action.payload;
        },
        addFilter(state) {
            if (!state.viewData.Filter) {
                state.viewData.Filter = { Col: 3, Data: [] };
            }
            state.viewData.Filter.Data.push({ Type: 'bool', Label: '', Name: '' });
        },
        removeFilter(state, action) {
            state.viewData.Filter.Data.splice(action.payload, 1);
        },
        changeFilter(state, action) {
            state.viewData.Filter.Data[action.payload.idx][action.payload.target] = action.payload.newValue;
            console.log(current(state.viewData));
            if (action.payload.target === 'Key') {
                var data = getDataFromKey(state.viewData.Data[0], action.payload.newValue);
                console.log(action.payload);
                console.log(current(data));
                if (action.payload.newValue === 'Onlycustomer') data = { Elementtype: 'checkbox', Type: '' };
                if (data.SpecialFilter) {
                    state.viewData.Filter.Data[action.payload.idx]['SpecialFilter'] = data.SpecialFilter;
                    state.viewData.Filter.Data[action.payload.idx]['FilterName'] = data.FilterName;
                }
                if (data.Elementtype === 'input') {
                    switch (data.Type) {
                        case 'date':
                        case 'datetime-local':
                            state.viewData.Filter.Data[action.payload.idx]['Type'] = 'date';
                            break;
                        case 'text':
                            state.viewData.Filter.Data[action.payload.idx]['Type'] = 'text';
                            break;
                        case 'number':
                            state.viewData.Filter.Data[action.payload.idx]['Type'] = 'number';
                            break;
                        default:
                            break;
                    }
                } else if (data.Elementtype === 'checkbox') {
                    state.viewData.Filter.Data[action.payload.idx]['Type'] = 'bool';
                }else if (data.Elementtype === 'checkboxnon') {
                    state.viewData.Filter.Data[action.payload.idx]['Type'] = 'boolnon';
                } else if (data.Elementtype === 'dropdown') {
                    state.viewData.Filter.Data[action.payload.idx]['Type'] = 'dropdown';
                    state.viewData.Filter.Data[action.payload.idx]['DropdownName'] = data.DropdownName ?? '';
                }
                state.viewData.Filter.Data[action.payload.idx]['Label'] = data.Label ?? data.Name ?? '';
                console.log(current(state.viewData));
            }
        },
        switchFilterOrder(state, action) {
            const add = action.payload.target > action.payload.shadow ? (action.payload.insertBefore ? -1 : 0) : action.payload.target < action.payload.shadow ? (action.payload.insertBefore ? 0 : 1) : 0;
            state.viewData.Filter.Data.splice(+action.payload.target + add, 0, state.viewData.Filter.Data.splice(action.payload.shadow, 1)[0]);
        },
        //#endregion Filters
        //#region Dashboard
        changeDashboard(state, action) {
            state.viewData.DashboardElements[action.payload.idx][action.payload.target] = action.payload.newValue;
        },
        changeDashboardUpdateTimer(state, action) {
            state.viewData.DashboardElements[0].UpdateTime = action.payload.newValue;
        },
        addDashboard(state, action) {
            state.viewData.DashboardElements.push({ Name: action.payload, Type: 'base', Headline: '', 'SQL-Data': '' });
        },
        removeDashboard(state, action) {
            state.viewData.DashboardElements.splice(action.payload, 1);
        },
        //#endregion Dashboard
        removeUnnecessaryData(state, action) {
            console.log(current(state.viewData));
            const keys = Object.entries(state.viewData.TableElements[0]).map(e => {
                return e[1].Key;
            });
            const nData = getAllNecessaryData(state.viewData.Data[0], keys);
            state.viewData.Data[0] = nData;
            console.log(current(state.viewData));
        },
    },
});

function fillOrder(table) {
    let previous = 0;
    Object.entries(table)
        .sort((a, b) => {
            return a[1].Order > b[1].Order ? 1 : -1;
        })
        .forEach((attributes, idx) => {
            if (idx === 0) attributes[1].Order = 1;
            else attributes[1].Order = previous + 1;
            previous = attributes[1].Order;
        });
}

function getSelectedKeys(elements) {
    let keys = [];
    elements.forEach(grp => {
        return {
            ...grp,
            Data: grp?.Data.forEach(col => {
                return col.forEach(row => {
                    if (row.Key) {
                        keys.push(row.Key);
                    }
                });
            }),
        };
    });
    console.log(keys);
    return keys;
}

function getAllNecessaryData(data, keys) {
    let x = {};
    Object.entries(data).forEach((attributes, idxObj) => {
        if (typeof attributes[1] === 'object') {
            if (keys.includes(attributes[1].Key)) {
                x = { ...x, [attributes[0]]: attributes[1] };
            } else x = { ...x, ...getAllNecessaryData(attributes[1], keys) };
        }
    });
    return x;
}

function getDataFromKey(data, target) {
    var x = null;
    Object.entries(data).forEach(key => {
        if (typeof key[1] === 'object' && (key[1].Designable === true || key[1].SpecialFilter === true) && (key[1].Label || key[1].Name)) {
            if (target === key[1].Key) {
                x = key[1];
            }
        } else if ((Array.isArray(key[1]) || typeof key[1] === 'object') && !key[1].Designable && key[1].Designable !== false) {
            x = getDataFromKey(key[1], target) ?? x;
        }
    });
    return x;
}

function switchElementGroup(state, action) {
    var x = state.viewData.CustomElements[action.payload.shadow.groupKey];
    state.viewData.CustomElements[action.payload.shadow.groupKey] = state.viewData.CustomElements[action.payload.target.groupKey];
    state.viewData.CustomElements[action.payload.target.groupKey] = x;
    console.log(current(state.viewData.CustomElements));
}

const store = configureStore({
    reducer: viewSlice.reducer,
});
export const viewActions = viewSlice.actions;
export default store;
