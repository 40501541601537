import { deleteCookie } from 'Helpers/MFXCookie';
import MFXLocalStorage from 'Helpers/MFXLocalStorage';


/**
 * Interface for the data response given from the back-end. The generic type-parameter is therefore placeholder for the real data interface,
 */
export interface ApiResult<T> {
  Data: T;
  /** Some additional information beside the main response data. */
  Meta: {
    /** True when the request succeeded.  */
    Success: boolean;
    /** Contains all error-messages to display. */
    ErrorMessages: [] | null;
    /** contains the time [ms] used by the database to generate the response data. */
    TimingDatabase: number;
    /** contains the time [ms] used from receiving the request on server side til the response has started sending back. */
    TimingServer: number;
    /** contains the time [ms] used from starting the request til it has been parsed. */
    TimingTotal: number;
  };
  ListMeta: {
    /** contains the entries count (number of total entries) */
    EntriesCount: number;
    /** contains the page count (number of total pages) */
    PagesCount: number;
    /** contains the page (returned page) */
    Page: number;
    /** contains the page size (how many items are viewed per Page) */
    PageSize: number;
  };
}


/**
 * Loads an URI and returns the resulting data.
 * @param {string} url URL for the request. 
 * @returns {Promise<ApiResult<T>>} Result of the request.
 */
export default async function loadJsonData<T = unknown>(url: string): Promise<ApiResult<T>> {  
  // store time of beginning
  const timeBegin = performance.now();
  // make request
  const response = await fetch(window.urlPrefix + encodeURI(url));
  if(response.status === 401){
    logger.test('401 Response: ' + response);
    logger.warning('401 Response: ' + response);

    //Access Denied - Logout User
    console.log('DDÖDÖSADÖASDÖASD');
    MFXLocalStorage.removeAllLocals();
    deleteCookie('MFX_Login');    
  }
  
  // start API request
  const data = (await response.json()) as ApiResult<T>;
  
  //Error Loggging
  if(data.Meta.ErrorMessages !== null && data.Meta.ErrorMessages.length !== 0){
    if(typeof data.Meta.ErrorMessages === 'string')
      logger.error(data.Meta.ErrorMessages);
    else
      logger.error(JSON.stringify(data.Meta.ErrorMessages));
  }else if (data.Meta.Success) {
    logger.information('Successfully loaded Data: ' + url);
  }

  // save total timing-data into response.Meta
  data.Meta.TimingTotal = performance.now() - timeBegin;

  // finally: return data
  return data;
}
