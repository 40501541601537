import ModalPopup from 'Components/Lib/Common/ModalPopup';
import { AddViewProvider } from 'Components/Lib/CRM/AddView';
import Addbutton from 'Components/Lib/Elements/Addbutton';
import MainSearch from 'Components/Lib/Elements/MainSearch';
import { getPropertiesPerType } from 'Helpers/getPropertiesPerType';
import { deleteCookie, getCookieValue } from 'Helpers/MFXCookie';
import LocalStorage from 'Helpers/MFXLocalStorage';
import SessionStorage from 'Helpers/MFXSessionStorage';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from 'Styles/images/logo.png';
import deleteData from '../Lib/Utils/deleteData';
import loadJsonData from '../Lib/Utils/loadJsonData';
import loadJsonPost from '../Lib/Utils/loadJsonPost';
import sessionedLink from '../Lib/Utils/sessionedLinks';
import URLS from '../Lib/Utils/URLs.API';

/**
 * Header (Logo, DB Change, Search, Version, Add Note, User, Logout)
 * @returns {JSX.Element} Rendered component
 */

const Topheader = () => {
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const [version, setVersion] = useState(null);
    const [mobile, setMobile] = useState(window.innerWidth <= window.mobileBreakPoint ? true : false);
    const [menuState, setMenuState] = useState({
        versionOpen: false,
        addMode: false,
    });
    const [databaseList, setDatabaseList] = useState(null);
    const [roleList, setRoleList] = useState(null);

    function handleResize() {
        if (window.innerWidth <= window.mobileBreakPoint) setMobile(true);
        else setMobile(false);
    }
    window.addEventListener('resize', handleResize);

    /* ---------- Load Versions and Databases --------- */
    useEffect(() => {
        if (LocalStorage?.getConnectionKey()) {
            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.Versions).then(data => {
                if (data.Meta.Success && data.Data) {
                    setVersion(data.Data);
                }
            });
            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetLoginableDatabases).then(data => {
                if (data.Meta.Success && data.Data) {
                    setDatabaseList(data.Data.Databases);
                }
            });
            loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetUserGroups).then(groups => {
                loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetUsers + '?Username=' + LocalStorage.getData('UserName')).then(data => {
                    if (data.Meta.Success && data.Data && data.Data[0]) {
                        var groupIDs = data.Data[0].OtherUserGroupIDs?.split(',').map(d => +d);
                        if (groupIDs) groupIDs?.unshift(data.Data[0].UserGroupID);
                        else groupIDs = [data.Data[0].UserGroupID];
                        setRoleList(
                            groupIDs.map(g => {
                                return groups?.Data?.find(group => group.UserGroupID === g)?.Name;
                            })
                        );
                    }
                });
            });
        }
    }, []);

    /* ---------- On Change DB --------- */
    function changeDB(val) {
        loadJsonPost('/' + LocalStorage?.getConnectionKey() + URLS.LoginOtherDB, { Database: val, Language: getCookieValue('MFX_LANGUAGE') ?? 'deutsch' }).then(data => {
            if (data.Meta.Success && data.Data) {
                setDatabaseList(data.Data.Databases);
                const key = LocalStorage.createDBStorage({ key: data.Data.ConnectionKey, db: val }, { primaryId: -1, primaryType: 'start', context: {} });
                window.open('/' + key, '_blank');
            }
        });
    }

    function changeRole(val) {
        LocalStorage.createSession('UserRole', val);
        window.location.reload();
    }

    return (
        <div id="mfx-topheader">
            {/* ---------- Left side of Header --------- */}

            <div id="mfx-logocontainer">
                {/* MULTIFLEX Logo */}
                <img src={Logo} id="logo" alt="Logo" />
                {/* renders CLIENT Logo  - via DataURI (from Database) */}
                <div id="clientlogo"></div>
            </div>

            {/* ---------- Right side of Header --------- */}
            <div id="mfx-infocontainer">
                {!mobile && (
                    <>
                        <span className="username">{LocalStorage.getUsername()}</span>
                        <div className="mfx-dropdown-element change-db">
                            <select onChange={e => changeDB(e.target.value)}>
                                <option>{LocalStorage.getDBName()}</option>
                                {databaseList?.map(database => {
                                    if (database.Name !== LocalStorage.getDBName()) return <option value={database.Name}>{database.Name}</option>;
                                })}
                            </select>
                        </div>
                        {roleList?.length > 1 && (
                            <div className="mfx-dropdown-element change-db">
                                <select value={LocalStorage.getData('UserRole')} onChange={e => changeRole(e.target.value)}>
                                    {roleList?.map(role => {
                                        return <option value={role}>{role}</option>;
                                    })}
                                </select>
                            </div>
                        )}
                    </>
                )}
                {/* ---------- MainSearch --------- */}
                <MainSearch />

                {/* ---------- Desktop Buttons (Help & AddNote) --------- */}
                {!mobile && (
                    <>
                        <div id="help">
                            <i className="icon-help" onClick={() => setMenuState({ ...menuState, versionOpen: true })}></i>
                        </div>
                        <div id="addNote">
                            <i className="icon-add" onClick={() => setMenuState({ ...menuState, addMode: true })}></i>
                        </div>
                    </>
                )}

                {/* ---------- Submenu --------- */}
                <nav id="user" className={submenuOpen ? 'active' : ''}>
                    <ul className="menu">
                        <li>
                            <i
                                className="icon-user"
                                onClick={() => {
                                    setSubmenuOpen(!submenuOpen);
                                    setTimeout(() => {
                                        setSubmenuOpen(false);
                                    }, 3000);
                                }}></i>
                            <ul className={`sub-menu ${submenuOpen ? 'open' : ''}`}>
                                {mobile && (
                                    <>
                                        <li>
                                            <div className="mfx-dropdown-element change-db">
                                                <select onChange={e => changeDB(e.target.value)}>
                                                    <option>{LocalStorage.getDBName()}</option>
                                                    {databaseList?.map(database => {
                                                        if (database.Name !== LocalStorage.getDBName()) return <option value={database.Name}>{database.Name}</option>;
                                                    })}
                                                </select>
                                            </div>
                                        </li>
                                        {roleList?.length > 1 && (
                                            <li>
                                                <div className="mfx-dropdown-element change-db">
                                                    <select value={LocalStorage.getData('UserRole')} onChange={e => changeRole(e.target.value)}>
                                                        {roleList?.map(role => {
                                                            return <option value={role}>{role}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                            </li>
                                        )}
                                        <li id="help">
                                            <button onClick={() => setMenuState({ ...menuState, versionOpen: true })}>{window.Dictionary?.getTranslation(1037)}</button>
                                        </li>
                                        <li id="addNote">
                                            <button onClick={() => setMenuState({ ...menuState, addMode: true })}>{window.Dictionary?.getTranslation(14206)}</button>
                                        </li>
                                    </>
                                )}
                                {/* ---------- Link to User --------- */}
                                <li>
                                    <Link to={sessionedLink('/processes/user')}>{window.Dictionary?.getTranslation(2489)}</Link>
                                </li>

                                {/* ---------- Link to Designer --------- */}

                                {!mobile && LocalStorage.getData('UserData').IsAdministrator && (
                                    <li>
                                        <Link to={'/designer/' + window.location.pathname.replace('processes', 'pages').slice(1) + '?layoutName=' + LocalStorage.getData('UserRole') ?? 'default'} target="_blank">
                                            {window.Dictionary?.getTranslation(14203)}{' '}
                                        </Link>
                                    </li>
                                )}

                                {/* ---------- Logout--------- */}
                                <li>
                                    <button
                                        className="no-styling"
                                        onClick={() => {
                                            deleteData('/' + LocalStorage?.getConnectionKey() + URLS.Logout)
                                                .then(data => {
                                                    if (data.Meta.Success) {
                                                        LocalStorage.removeAllLocals();
                                                        SessionStorage.removeAllStorage();
                                                        SessionStorage.createSession('Logout',window.location.href);
                                                        deleteCookie('MFX_Login');
                                                    }
                                                })
                                                .catch(e => {
                                                    console.log(e);
                                                });
                                        }}>
                                        {window.Dictionary?.getTranslation(3317)}
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>

            {/* ---------- POPUPS --------- */}
            {
                /*
                 * Renders a PopUp with the Main Information
                 */
                menuState.versionOpen && (
                    <ModalPopup>
                        <div class="mfx-action-buttons">
                            <button type="button" className="mfx-button mfx-button-type-icon" onClick={() => setMenuState({ ...menuState, versionOpen: false })}>
                                <i class="icon-close"></i>
                            </button>
                        </div>
                        <h3>{window.Dictionary.getTranslation(791)}</h3>
                        <p>.Net Version: {version.DotNetMajorVersion}</p>
                        <p>BuildDate: {new Date(Date.parse(version.BuildDate)).toLocaleString('de-DE')}</p>
                        <p>CRM Version: {version.PLXSuite}</p>
                        <p>DB Version: {version.DataBaseVersion}</p>
                        <p>Connection-Key: {LocalStorage?.getConnectionKey()}</p>
                        <button onClick={() => setMenuState({ ...menuState, versionOpen: false })}>{window.Dictionary.getTranslation(1650)}</button>
                    </ModalPopup>
                )
            }
            {
                /*
                 * Renders a Note-Addform
                 */
                menuState.addMode && (
                    <ModalPopup modalClass="mfx-add-modal">
                        <AddViewProvider changeView={() => setMenuState({ ...menuState, addMode: false })} viewName={'NoteAddView'} viewSettings={getPropertiesPerType('NoteView')}>
                            <div className="mfx-action-buttons">
                                <Addbutton disableChangeHandler={() => setMenuState({ ...menuState, addMode: false })} disableMode={() => setMenuState({ ...menuState, addMode: false })} save={false} />
                            </div>
                        </AddViewProvider>
                    </ModalPopup>
                )
            }
        </div>
    );
};

export default Topheader;
