import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ChangeInputElement from '../Elements/ChangeInputElement';
import { viewActions } from '../../../../Helpers/Stores/viewStore';
import ChangeCheckbox from '../Elements/ChangeCheckbox';
import InputElement from '../Elements/InputElement';
import YesOrNoElement from '../Elements/YesOrNo';

interface ITableElementsData {
    Key: string;
    Label: string | number;
    Order: number;
    Sortable: boolean;
}

export default function TableView(props: any) {
    const dispatch = useDispatch();
    const TableElements = props?.data?.TableElements ? props?.data?.TableElements[0] : {};
    const Data = props?.data?.Data ? props.data.Data[0] : null;
    let target: any;
    let shadow: any;

    function handleInputChange(settingsObject: any, target: string) {
        dispatch(viewActions.changeValue({ key: settingsObject.key, newValue: settingsObject.newValue, target: target }));
    }

    function dragit(event: any) {
        shadow = event.target;
    }
    function dragover(e: any, filter = false) {
        e.preventDefault();
        var prev = target;
        target = e.target;
        for (let c = 0; c < 3; c++) {
            if (target.id && target.draggable === true) break;
            target = target.parentNode;
        }
        if (prev && prev !== target) {
            prev.style.borderLeft = '';
            prev.style.borderRight = '';
        }
        if (target !== shadow) {
            target.style[target.clientWidth / 2 > e.clientX - target.offsetLeft ? 'borderLeft' : 'borderRight'] = '3px solid #58fa7c';
            target.style[target.clientWidth / 2 < e.clientX - target.offsetLeft ? 'borderLeft' : 'borderRight'] = '';
        }
    }

    function dragEnd(e: any, filter = false) {
        target.style.borderLeft = '';
        target.style.borderRight = '';
        if (target?.id !== '' && shadow?.id !== '' && !filter) dispatch(viewActions.switchOrder({ target: target.id, shadow: shadow.id, insertBefore: target.clientWidth / 2 > e.clientX - target.offsetLeft }));
        else if (target?.id !== '' && shadow?.id !== '') dispatch(viewActions.switchFilterOrder({ target: target.id, shadow: shadow.id, insertBefore: target.clientWidth / 2 > e.clientX - target.offsetLeft }));
        else alert('Drag and Drop failed since the object it was dropped on was not part of the Table');
    }
    function renderGenericTds() {
        return (
            <>
                {Object.entries(TableElements)
                    .sort((a: any, b: any) => {
                        return a[1].Order > b[1].Order ? 1 : -1;
                    })
                    .map((entry: any, idx) => {
                        let dataKeys = (entry[1] as ITableElementsData).Key.split('-');
                        if (dataKeys[0] === 'Data') {
                            dataKeys.shift();
                        }
                        let designData = entry[1];

                        return (
                            <div key={idx} id={entry[0]} className="mfx-divtable-column" draggable={true} onDragStart={dragit} onDragOver={dragover} onDragEnd={dragEnd}>
                                <div className="mfx-divtable-column-head">
                                    <ChangeInputElement title={entry[0]} value={entry[1].Label} type={'text'} dataKey={designData.Key} onChangeInput={(settings: object) => handleInputChange(settings, 'Label')} />
                                </div>
                                <div className="mfx-divtable-column-data">
                                    {designData.Elementtype !== 'checkbox' && <ChangeInputElement value={entry[1].DefaultValue} type={designData.Type} dataKey={designData.Key} onChangeInput={(settings: object) => handleInputChange(settings, 'DefaultValue')} />}
                                    {designData.Elementtype === 'checkbox' && <ChangeCheckbox value={entry[1].DefaultValue} dataKey={designData.Key} onChangeInput={(settings: object) => handleInputChange(settings, 'DefaultValue')} />}
                                </div>
                            </div>
                        );
                    })}
            </>
        );
    }
    function renderFilters() {
        return (
            <>
                <div className="mfx-grid-container">
                    {props.data?.Filter?.Data?.map((data: any, idx: any) => {
                        let activeClass = props.curFilter === idx || props.data.Filter.Data.length === 1 ? 'active' : '';

                        return (
                            <div onClick={e => props.onSelectFilter(e, idx)} id={idx} key={idx} className={'mfx-col-' + props.data.Filter.Col + ' table-filter ' + activeClass} draggable={true} onDragStart={dragit} onDragOver={e => dragover(e, true)} onDragEnd={e => dragEnd(e, true)}>
                                {data.Type !== 'bool' && data.Type !== 'boolnon' && <InputElement designData={data} disableMode={true} index={idx} onClick={(e: any) => props.onSelectFilter(e, idx)} />}
                                {(data.Type === 'bool'  || data.Type === 'boolnon') && <YesOrNoElement designData={data} index={idx} onClick={(e: any) => props.onSelectFilter(e, idx)} />}
                            </div>
                        );
                    })}
                </div>
                <div className="mfx-filter-button">
                    <button onClick={props.addFilter}>{window.Dictionary.getTranslation(14865)}</button>
                </div>
            </>
        );
    }
    return (
        <>
            <div className="mfx-gp-container">
                <div className="mfx-view">
                    <div className="mfx-divtable-container">
                        <div className="mfx-divtable">{Data && renderGenericTds()}</div>
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: '12px' }}></div>
            {props.addFilter && <div className="mfx-gp-container">{renderFilters()}</div>}
        </>
    );
}
