enum URLS {
    AllLeadsData = '/api/Lead/GetLeads',
    AllCustomersData = '/api/Customer/GetCustomers',
    AllOffersData = '/api/OfferOrder/GetOffersOrders',
    AllTasksData = '/api/Task/GetTasks',
    AllContactPersonsData = '/api/ContactPerson/GetContactPersons',
    AssertionOptions = '/assertionOptions',
    BusinessProcess = '/api/Layout/GetBusinessProcess',
    Connection = '/api/Connection/Connection',
    Customer = '/api/maindata/customer/{id}',
    CustomerMenu = '/api/menu/customer',
    CreateCustomerWithContact = '/api/Task/CreateTask',
    CreateContactPerson = '/api/ContactPerson/CreateContactPerson',
    CreateTask = '/api/Task/CreateTask',
    CreateLead = '/api/Lead/CreateLead',
    CreateNote = '/api/Note/CreateNote',
    CreateProcess = '/api/Process/CreateProcess',
    Databases = '/api/Account/Databases',
    DeleteLead = '/api/Lead/DeleteLead',
    DeleteTask = '/api/Task/DeleteTask',
    DeleteContactPerson = '/api/ContactPerson/DeleteContactPerson',
    DeleteNote = '/api/Note/DeleteNote',
    AppSettings = '/api/Settings/AppSettings',
    GeolocationDB = '//geolocation-db.com/json',
    GetEmployeeInfo = '/api/Account/GetEmployeeInfo',
    GetOrganisationalInfo = '/api/Common/GetOrganisationalUnitInfo',
    GetTask = '/api/Task/GetTask?TaskIndex=',
    GetContactPerson = '/api/ContactPerson/GetContactPerson?ContactPersonIndex=',
    GetAppointments = '/api/Appointment/GetAppointments',
    GetCustomer = '/api/Customer/GetCustomer?CustomerNumber=',
    GetCurrentLayoutRole = '/api/Layout/GetCurrentLayout',
    GetGoogleMapsMarkers = '/api/Customer/GetMarkersForMap',
    GetItem = '/api/Item/GetItem',
    GetDropdownValues = '/api/Common/GetDropdownValues?ListType=',
    GetPageLayouts = '/api/Layout/GetPageLayouts',
    GetProcessProfCrit = '/api/Process/GetProfCrit?Assignment=',
    GetLead = '/api/Lead/GetLead?LeadProcessIndex=',
    GetNote = '/api/Note/GetNote',
    GetNotes = '/api/Note/GetNotes',
    GetOffer = '/api/OfferOrder/GetOfferOrder?OfferOrderIndex=',
    GetOfferOrderPositions = '/api/OfferOrderPosition/GetOfferOrderPositions',
    GetBusinessProcessPage = '/api/Layout/GetBusinessProcessPage',
    GetBusinessProcess = '/api/Layout/GetBusinessProcess?BusinessProcessName=',
    GetDashboard = '/api/Dashboard/GetDashboard',
    GetLoginableDatabases = '/api/Account/GetLoginableDatabases',
    GetProcess = '/api/Process/GetProcess?ProcessIndex=',
    GetTimeRecording = '/api/Process/GetProcess?ProcessIndex=',
    GetUsers = '/api/Roles/GetUsers',
    GetUserGroups = '/api/Roles/GetUserGroups',
    GetSystemSettings = '/api/Common/GetSystemSettings',
    Languages = '/api/Translate/GetLanguages',
    LanguageJson = '/api/Translate/GetLanguageJson',
    Login = '/api/Account/Login',
    Logout = '/api/Account/Logout',
    Logging = '/api/Logging/LogClient',
    LoginOtherDB = '/api/Account/LoginOtherDB',
    MakeCredentialOptions = '/makeCredentialOptions',
    MainMenu = '/api/menu/main',
    Offers = 'Offers',
    OfferMenu = '/api/menu/offer',
    Process = '/api/crm/processes/{id}',
    Quicklinks = 'Quicklinks',
    Reports = '/api/Reports/GetReportsLink',
    Search = '/api/FTS/Search',
    UpdateCustomer = '/api/Customer/UpdateCustomer',
    UpdateLead = '/api/Lead/UpdateLead',
    UpdateProcess = '/api/Process/UpdateProcess',
    UpdateTask = '/api/Task/UpdateTask',
    UpdateContactPerson = '/api/ContactPerson/UpdateContactPerson',
    ViewLayouts = '/api/Layout/GetBusinessProcessView',
    Versions = '/api/Information/GetVersions',
    GetAllSystemInfo = '/api/SystemInfo/GetInvoiceSystemInfoDTO',
    SignOfferOrder = '/api/Signing/SignOfferOrder',
    // Designer only
    GetViewLayouts = '/api/Layout/GetBusinessProcessView?ViewName=',
    GetAllBusinessProcessViewNamesAndLayoutNames = '/api/Layout/GetAllBusinessProcessViewNamesAndLayoutNames',
    GetPageLayoutsAndLayoutNames = '/api/Layout/GetPageLayoutsAndLayoutNames',
    PageLayouts = '/api/Layout/GetBusinessProcessPage',
    PutBusinessProcessPage = '/api/Layout/PutBusinessProcessPage',
    PutBusinessProcess = '/api/Layout/PutBusinessProcess',
    PostBusinessProcessPage = '/api/Layout/PostBusinessProcessPage',
    DeleteBusinessProcessPage = '/api/Layout/DeleteBusinessProcessPage',
    PostBusinessProcess = '/api/Layout/PostBusinessProcess',
    PostBusinessProcessView = '/api/Layout/PostBusinessProcessView',
    PutBusinessProcessView = '/api/Layout/PutBusinessProcessView',
    DeleteBusinessProcessView = '/api/Layout/DeleteBusinessProcessView',
    NewView = 'NewView',
    RemoveUserFromGroup = '/api/Roles/RemoveUserFromGroup',
    DeleteUserGroup = '/api/Roles/DeleteUserGroup',
    AddUserToGroup = '/api/Roles/AddUserToGroup',
    CreateUserGroup = '/api/Roles/CreateUserGroup',
}

export default URLS;
