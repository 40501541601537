const PREFIX = 'MFX_';
const DBPREFIX = 'MFXDB_';
const CONNECTIONKEYS = 'MFX_CONNECTIONKEYS'; //ARRAY WITH DB NAMES

export interface ILocal {
    primaryId: number;
    primaryType: 'start' | 'customer' | 'issue' | 'offer' | 'task' | 'contactperson' | 'lead' | 'customervisit' | '';
    context: {
        customerId?: null | number;
        customerName?: null | string;
        issueId?: null | number;
        curUrl?: string;
    };
    lastUrl?: string;
}
export interface ILoginData {
    db: string;
    key: string;
}

class LocalStorage {
    private storage = localStorage;
    public createSession(key: string, data: any) {
        this.storage.setItem(key, JSON.stringify(data));
    }
    public removeSession(key: string) {
        this.storage.removeItem(key);
    }
    private load(key: string): string | null {
        return this.storage.getItem(key);
    }
    private loadParsed<T>(key: string): T {
        return JSON.parse(this.storage.getItem(key) || 'null') as T;
    }

    private store(key: string, data: string) {
        return this.storage.setItem(key, data);
    }

    public createTab(data: ILocal, activate = false, redirect: boolean = true, id: string = '', redirectUrl: string = '') {
        let curDBData = this.getDBData() as any;
        let curDbKeys = this.getDBKeys();
        let newTabName = curDBData.prefix + '_' + data.primaryType.toUpperCase() + '_' + data.primaryId;

        if (!curDbKeys.includes(newTabName)) {
            curDBData.tabs = { ...curDBData.tabs, [newTabName]: { ...data, redirectUrl: redirectUrl } };
            curDBData.keys.push(newTabName);

            this.store(PREFIX + curDBData.dbname, JSON.stringify(curDBData));

            if (activate) this.setCurrent(newTabName, redirect, redirectUrl);
        }

        return newTabName;
    }

    //Only on LOGIN
    public createDBStorage(loginData: ILoginData, data: ILocal, redirectUrl) {
        let curDBName = loginData.db.toUpperCase().replace(' ', '');
        let curKey = loginData.key;
        let prevKeys: any = JSON.parse(this.load(CONNECTIONKEYS) ?? '{}');

        logger.debug(prevKeys);

        let newStorage = {
            tabs: { [DBPREFIX + loginData.db + '_START']: { ...data, redirectUrl: redirectUrl } },
            current: DBPREFIX + loginData.db + '_START',
            conKey: curKey,
            keys: [DBPREFIX + loginData.db + '_START'],
            prefix: DBPREFIX + loginData.db,
            dbname: loginData.db,
        };

        this.store(PREFIX + loginData.db, JSON.stringify(newStorage));
        this.store(CONNECTIONKEYS, JSON.stringify({ ...prevKeys, [curDBName]: curKey })); //ADD NET KEYS

        return DBPREFIX + loginData.db + '_START';
    }

    public getCurrentPath(): string {
        return (this.getDBData() as any).current || '';
    }
    
    public getData(key: string) {
        return this.storage.getItem(key) ? JSON.parse(this.storage.getItem(key) as string) : '';
    }

    public getCurrentTabUrl(): string | null {
        let db = this.getCurrentDB();
        let dbData = this.loadParsed(PREFIX + db);

        return (dbData as any)?.current ?? null;
    }

    public getMainTabUrl(): string | null {
        let db = this.getCurrentDB();
        let dbData = this.loadParsed(PREFIX + db);
        //this.setCurrent((dbData as any)?.keys[0], true);

        return (dbData as any)?.keys[0] ?? null;
    }

    private getCurrentDB(): string {
        let db = document.location.href.split(DBPREFIX)[1];
        if (db) {
            db = db.split('_')[0];
            return db;
        }
        return '';
    }

    public getConnectionKey(): string | null {
        let db = this.getCurrentDB();
        let keys = this.loadParsed(CONNECTIONKEYS) as any;

        if (keys) return keys[db];

        return ''; //this.load(CONNECTIONKEY);
    }

    public setCurrent(key: string, redirect: boolean, redirectUrl: string = ''): void {
        if (this.hasKeyInDB(key)) {
            let curDBData = this.getDBData() as any;
            curDBData.tabs[curDBData.current].lastUrl = document.location.href;

            curDBData.current = key;
            this.store(PREFIX + curDBData.dbname, JSON.stringify(curDBData));
            if (redirect && redirectUrl) {
                document.location = '/' + key + redirectUrl;
            } else if (curDBData.tabs[key].lastUrl) document.location = curDBData.tabs[key].lastUrl;
            else document.location = '/' + key + curDBData.tabs[key].redirectUrl;
        }
        //document.location = '/' + key + redirectUrl;
    }

    public removeTab(key: string) {
        let db = { ...(this.getDBData() as any) };
        let newTabs = {};
        let newKeys = [] as string[];

        Object.keys(db.tabs).forEach(tabname => {
            if (!tabname.includes(key)) {
                newTabs = { ...newTabs, [tabname]: db.tabs[tabname] };
                newKeys.push(tabname);
            }
        });

        db.tabs = newTabs;
        db.keys = newKeys;
        db.current = db.prefix + '_START';

        this.store(PREFIX + db.dbname, JSON.stringify(db));

        document.location = '/' + db.current;
    }

    public removeAllTabs() {
        let db = { ...(this.getDBData() as any) };

        db.keys = db.keys.splice(0, 1);
        db.tabs = { [db.prefix + '_START']: db.tabs[db.prefix + '_START'] };
        db.current = db.prefix + '_START';

        this.store(PREFIX + db.dbname, JSON.stringify(db));

        document.location = '/' + db.current;
    }

    public removeAllLocals(reload = true) {
        this.storage.clear();
        if (reload) document.location.reload();
    }

    public getLocalData(key?: string | null): ILocal | null {
        key = this.getConnectionKey();
        if (!key) return null;
        return JSON.parse(this.load(PREFIX + key) || '{}') as ILocal;
    }

    public getLocalDataAsString(key?: string | null): String | null {
        if (!key) key = this.getConnectionKey() || null;

        return this.load(PREFIX + key) || '';
    }

    public getDBDataAsString(key: string): String {
        let db = this.getDBData();

        return JSON.stringify(db[key]) || '';
    }

    public setLocalData(key: string, LocalData: ILocal) {
        this.store(PREFIX + key, JSON.stringify(LocalData));
    }

    public hasKeyInDB(key: string): boolean {
        if (!this.getDBKeys()) return false;
        return this.getDBKeys().indexOf(key) !== -1;
    }

    public hasTabInDB(key: string): boolean {
        return this.getDBKeys()[key] !== -1;
    }
    public getUsername() {
        return this.storage.getItem('UserData') ? (JSON.parse(this.storage.getItem('UserData') as any) as any).CombinedName : '';
    }
    public getDBKeys(): string[] {
        let dbData = this.loadParsed<string[]>(PREFIX + this.getCurrentDB()) || [];
        return (dbData as any).keys || [];
    }

    public getDBName(): string[] {
        let dbData = this.loadParsed<string[]>(PREFIX + this.getCurrentDB()) || [];
        return (dbData as any).dbname || [];
    }

    public getDBData(): {} {
        return (this.loadParsed<string[]>(PREFIX + this.getCurrentDB()) as any) || {};
    }

    public getAllTabData(): {} {
        return (this.loadParsed<string[]>(PREFIX + this.getCurrentDB()) as any)?.tabs || {};
    }

    public getAllTabKeys(): {} {
        return (this.loadParsed<string[]>(PREFIX + this.getCurrentDB()) as any)?.keys || {};
    }

    public hasTabs(): {} | null {
        return (this.loadParsed<string[]>(PREFIX + this.getCurrentDB()) as any)?.tabs || null;
    }

    public getTabData(key = ''): ILocal {
        let db = this.loadParsed<string[]>(PREFIX + this.getCurrentDB()) as any;
        let tabs = db?.tabs || {};
        if (key !== '') return tabs[key] || {};
        else return tabs[db?.current] || {};
    }
}

const MFXLocalStorage = new LocalStorage();

export default MFXLocalStorage;
