/**
 * Renders an generic dropdown with 2 Options: yes or no
 * @returns {JSX.Element} The dropdown with 2 Options: yes or no.
 * @param {any} props given by caller
 */

import { useEffect, useState } from 'react';

const YesOrNoElement = props => {
    let radioLabel = window.Dictionary.getTranslation(props.designData.Name);
    let radioID = 'radio-' + radioLabel.replace(/\s/g, '-').toLowerCase();
    const [curState, setCurState] = useState(props.value ? props.value : props.noNeutral ? 0 : -1);
    const handleOnChange = () => {
        if (props.disableMode || props.designData.ReadOnly) return;
        let state = curState - 1; //because useState is too lazy
        if (state === (props.noNeutral ? -1 : -2)) setCurState(1);
        else setCurState(state);
    };
    console.log(curState);
    console.log(props);
    useEffect(() => {
        if (props.onChangeHandler && curState !== null) {
            console.log({ newValue: curState === -1 ? '' : curState, designData: props.designData });
            props.onChangeHandler({ newValue: curState === -1 ? '' : curState, designData: props.designData });
        }
    }, [curState]);

    useEffect(() => {
        console.log(props.value);
        setCurState(props.value || props.value === 0 ? props.value : -1);
    }, [props.value]);

    return (
        <div className="mfx-dropdown-element">
            {props.designData.Label && <label htmlFor={radioID}>{window.Dictionary.getTranslation(props.designData.Label)}</label>}
            <div id={radioID} name={radioID} defaultValue={props.designData.Value ?? props.value} value={props.value} disabled={props.disableMode || props.designData.ReadOnly} onClick={handleOnChange} className={`multiple-checkbox state-${curState}`}></div>
        </div>
    );
};

export default YesOrNoElement;
