import { useEffect, useState } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import InputElement from './InputElement';
import YesOrNoElement from './YesOrNo';
import Dropdown from './Dropdown';
import LocalStorage from 'Helpers/MFXLocalStorage';
import URLS from '../Utils/URLs.API';
import loadJsonData from '../Utils/loadJsonData';
import SessionStorage from '../../../Helpers/MFXSessionStorage';

export default function Filter(props: any) {
    const [filterValues, setFilterValues] = useState(null as any);
    const [specialFilter, setSpecialFilter] = useState([
        /*{ Key: 'OnlyOwnTasks', Value: props.specialFilter.includes('OnlyOwnTasks=true') ? 1 : props.specialFilter.includes('OnlyOwnTasks=false') ? 0 : '' },
        { Key: 'FromRole', Value: props.specialFilter.includes('FromRole=true') ? 1 : props.specialFilter.includes('FromRole=false') ? 0 : '' },*/
    ] as any);

    const [personInChargeID, setPersonInChargeID] = useState(null as any);
    const [personInChargeNumber, setPersonInChargeNumber] = useState(null as any);
    /// Called when the Filter is first initialized (a Table Page gets opened)
    /// If there is no filter parameter in the url it will look through the DefaultFilters one can set in the Designer and add them to the url if they exist
    useEffect(() => {
        var storageFilters = SessionStorage.getData(LocalStorage?.getTabData()?.primaryType + '_' + props.filterName);
        if (!props.filterQuery && !storageFilters && props.filters) {
            let query = '';

            props.filters.Data.filter(f => f.DefaultValue && !f.SpecialFilter).forEach(d => {
                if (typeof d.DefaultValue === 'string' && d.DefaultValue.toLowerCase().split('.')[0] === 'userdata') {
                    d.DefaultValue = LocalStorage?.getData('UserData') ? LocalStorage?.getData('UserData')[d.DefaultValue.split('.')[1]] : d.DefaultValue;
                    if (isNaN(d.DefaultValue)) d.DefaultValue = 999;
                }
                if (d.Type === 'bool' || d.Type === 'boolnon') {
                    changeFilterValuesHandler({ newValue: d.DefaultValue, designData: d });
                }

                if ((d.Type === 'date' || d.Type === 'datetime-local') && d.DefaultValue === 'NOW') {
                    d.DefaultValue = new Date().toISOString().substring(0, 10);
                }
                if (d.Operator === 'IS') query += (query !== '' ? ' AND (' : '(') + d.Key.replaceAll('-', '.') + ' IS ' + d.DefaultValue + ')';
                else query += (query !== '' ? ' AND (' : '(') + d.Key.replaceAll('-', '.') + ' ' + (d.Operator ?? '=') + ' "' + (d.Operator === 'LIKE' ? '%' + d.DefaultValue + '%' : d.DefaultValue) + '")';
            });
            if (query) props.setUrlParams(props.filterName, query);
        }
        if (storageFilters && props.filters) {
            storageFilters
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('"', '')
                .replaceAll('%', '')
                .replaceAll(' OR ', ' AND ')
                .split(' AND ')
                .forEach((filter: any) => {
                    const filterData = filter.split(' ');
                    var filterDesign = props.filters.Data.find(d => d.Key === filterData[0].replace('.', '-'));
                    if (filterDesign?.Type === 'bool' || filterDesign?.Type === 'boolnon') {
                        changeFilterValuesHandler({ newValue: filterData[2], designData: filterDesign });
                    }
                });
            props.setUrlParams(props.filterName, storageFilters);
        } else if (props.filterQuery && props.filters) {
            props.filterQuery
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('"', '')
                .replaceAll('%', '')
                .replaceAll(' OR ', ' AND ')
                .split(' AND ')
                .forEach((filter: any) => {
                    const filterData = filter.split(' ');
                    var filterDesign = props.filters.Data.find(d => d.Key === filterData[0].replace('.', '-'));
                    if (filterDesign?.Type === 'bool' || filterDesign?.Type === 'boolnon') {
                        changeFilterValuesHandler({ newValue: filterData[2], designData: filterDesign });
                    }
                });
        }
        if(props.filters){
            props.filters.Data.filter(f => f.ReadOnly).forEach(f => {
                if (f.Type === 'bool' || f.Type === 'boolnon') {
                    changeFilterValuesHandler({ newValue: f.DefaultValue, designData: f });
                }
            });
        }
        //#region SpecialFilter
        if (props.specialFilter && props.filters) {
            props.specialFilter.split('&').forEach((filter: any) => {
                if (!filter) return;
                const filterData = filter.split('=');
                var filterDesign = props.filters.Data.find(d => d.FilterName === filterData[0]);
                if (filterDesign && filterDesign.Type === 'bool') {
                    setSpecialFilterValue(filterData[0], filterData[1] === 'true' ? 1 : filterData[1] === 'false' ? -1 : 0, filterDesign);
                }
                if (filterDesign && filterDesign.Type === 'boolnon') {
                    setSpecialFilterValue(filterData[0], filterData[1] === 'true' ? 1 : 0, filterDesign);
                } else if (filterDesign) {
                    setSpecialFilterValue(filterData[0], filterData[1], filterDesign);
                }
            });
        }
        //#endregion SpecialFilter

        /// Tells the Table that the data can be loaded (if not used 2 api calls would be made and most of the time the unfiltered result overwrites the filtered since it takes longer)
        props.setDefaultFiltersLoaded(true);
    }, []);

    function changeFilterValuesHandler(valueObject: any) {
        setFilterValues(prev => {
            return {
                ...prev,
                [(valueObject.designData as any).Label]: { value: valueObject.newValueNum ?? (typeof valueObject.newValue === 'object' && typeof valueObject.newValue.getMonth !== 'function') ? valueObject.newValue.value : valueObject.newValue, designData: valueObject.designData },
            };
        });
    }

    /// Called when the "Filter" button gets pressed.
    /// Adds the filter that was given in the filter input fields to the url
    /// If a specific filter already exists in the url it gets ignored
    function filterTable() {
        let query: string = props.filterQuery ?? '';
        if (filterValues) {
            const queryRep = query.replaceAll('(', '').replaceAll(')', '').replaceAll('%', '');
            Object.entries(filterValues).forEach((val: any) => {
                const key = val[1].designData.Key.replaceAll('-', '.'); //filterHelper(val[1].designData.Key.replaceAll('-', '.'));
                if (val[1].value === '' && (val[1].designData.Type === 'bool' || val[1].designData.Type === 'boolnon') && queryRep.includes(key + ' =')) {
                    const inx = query.indexOf(' AND (' + key + ' = "');
                    query = query.substring(0, inx) + query.substring(inx + val[1].designData.Key.length + 13);
                    return;
                } else if (val[1].value === '' || val[1].value === undefined) return;
                if (queryRep.includes(key + ' ' + (val[1].designData.Operator ?? '=') + ' "' + val[1].value + '"')) return;
                if ((val[1].designData.Type === 'bool' || val[1].designData.Type === 'boolnon') && queryRep.includes(key + ' =')) {
                    const inx = query.indexOf(key + ' = "') + val[1].designData.Key.length + 4;
                    query = query.substring(0, inx) + val[1].value + query.substring(inx + 1);
                } else if (val[1].designData.Type === 'date' && (!val[1].designData.Operator || val[1].designData.Operator !== '=') && queryRep.includes(key + ' ' + val[1].designData.Operator)) {
                    const inx = query.indexOf(key + ' ' + val[1].designData.Operator + ' "') + val[1].designData.Key.length + 3 + val[1].designData.Operator.length;
                    query = query.substring(0, inx) + val[1].value + query.substring(inx + 10);
                } else if (queryRep.includes(key + ' ' + (val[1].designData.Operator ?? '='))) {
                    const inx = query.indexOf(key + ' ' + (val[1].designData.Operator ?? '='));
                    query = query.substring(0, inx) + key + ' ' + (val[1].designData.Operator ?? '=') + ' "' + (val[1].designData.Operator === 'LIKE' ? '%' + val[1].value + '%' : val[1].value) + '" OR ' + query.substring(inx);
                } else {
                    query += (query !== '' ? ' AND (' : '(') + key + ' ' + (val[1].designData.Operator ?? '=') + ' "' + (val[1].designData.Operator === 'LIKE' ? '%' + val[1].value + '%' : val[1].value) + '")';
                }
                if (val[1].designData.Type !== 'bool' && val[1].designData.Type !== 'boolnon' && (val[1].designData.Type !== 'date' || (val[1].designData.Type === 'date' && val[1].designData.Operator === '='))) delete filterValues[val[1].designData.Label];
            });
            setFilterValues({ ...filterValues });
            props.setUrlParams(props.filterName, query);
        }
        specialFilter?.forEach(f => props.setSpecialFilter(f.Key + '=' + f.Value ?? 'null'));
    }

    useEffect(() => {
        console.log(specialFilter);
    }, [specialFilter]);
    function getSpecialFilterValue(key) {
        return specialFilter.find(f => f.Key === key)?.Value;
    }
    function setSpecialFilterValue(key, value, designData = null as any) {
        setSpecialFilter(prev => {
            console.log(key);
            console.log(value);
            console.log(designData);
            var specialCopy = JSON.parse(JSON.stringify(prev));
            var inx = specialCopy.findIndex(f => f.Key === key);
            if (inx !== -1) {
                specialCopy.splice(inx, 1, { Key: key, Value: designData?.Type === 'bool' || designData?.Type === 'boolnon' ? (value === 1 ? true : false) : value });
            } else {
                specialCopy.push({ Key: key, Value: designData?.Type === 'bool' || designData?.Type === 'boolnon' ? (value === 1 ? true : false) : value });
            }
            return specialCopy;
        });
    }

    /// Called when the "x" button in the "Selected Filter" section gets clicked
    /// Deletes the specified filter from the url.
    function removeFilter(filterData: any, designData: any, readOnly: boolean) {
        if (!readOnly) {
            const filterValue = filterData.slice(2).join(' ');
            setFilterValues(prev => {
                return {
                    ...prev,
                    [designData.Label]: { value: '', designData: designData },
                };
            });

            let query = '';
            if (filterData[1] === 'IS') query = props.filterQuery.replace(filterData[0].replaceAll('-', '.') + ' ' + (filterData[1] ?? '=') + ' ' + filterValue, '');
            else query = props.filterQuery.replace(filterData[0].replaceAll('-', '.') + ' ' + (filterData[1] ?? '=') + ' "' + (filterData[1] === 'LIKE' ? '%' + filterValue + '%' : filterValue) + '"', '');
            query = query.replace(' OR )', ')').replace('( OR ', '(').replace('OR  OR', 'OR').replace(' AND ()', '').replace('() AND ', '').replace('()', '');
            props.setUrlParams(props.filterName, query);
        }
    }

    function removeAllFilters() {
        var filterVals = {};
        Object.entries(filterValues)
            .filter((f: any) => f[1].designData.ReadOnly)
            .forEach(f => {
                filterVals = { ...filterVals, [f[0]]: f[1] };
            });
        setFilterValues(filterVals);
        var query = '';
        props.filters.Data.filter(f => f.ReadOnly).forEach(f => {
            if (query) query += ' AND ';
            query += '(' + f.Key + ' ' + (f.Operator ?? '=') + ' "' + (f.Operator === 'LIKE' ? '%' + f.DefaultValue + '%' : f.DefaultValue) + '")';
        });

        props.setUrlParams(props.filterName, query);
    }

    useEffect(() => {
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetDropdownValues + 'PersonInChargeID').then(data => {
            setPersonInChargeID(data.Data);
        });
        loadJsonData('/' + LocalStorage?.getConnectionKey() + URLS.GetDropdownValues + 'PersonInChargeNumber').then(data => {
            setPersonInChargeNumber(data.Data);
        });
    }, []);

    return (
        <>
            {props.filterActive && (
                <div className="mfx-filter">
                    {/*<h3>{window.Dictionary.getTranslation(835)}</h3>*/}
                    {props.filterQuery && (
                        <div className="mfx-grid-container selected-filter">
                            <div className="mfx-col-12">
                                <h4>{window.Dictionary.getTranslation(14231)}: </h4>
                                <ul>
                                    {props.filterQuery
                                        .replaceAll('(', '')
                                        .replaceAll(')', '')
                                        .replaceAll('"', '')
                                        .replaceAll('%', '')
                                        .replaceAll(' OR ', ' AND ')
                                        .split(' AND ')
                                        .map((filter: any) => {
                                            const filterData = filter.split(' ');
                                            var filterDesign = props.filters.Data.find(d => d.Key === filterData[0].replaceAll('.', '-') && (d.Operator ?? '=') === filterData[1]);
                                            const filterValue =
                                                filterData[0].replaceAll('.', '-') === 'PersonInChargeID'
                                                    ? personInChargeID?.find(p => p.Value === filterData.slice(2).join(' '))?.Name
                                                    : filterData[0].replaceAll('.', '-') === 'PersonInChargeNumber'
                                                    ? personInChargeNumber?.find(p => p.Value === filterData.slice(2).join(' '))?.Name
                                                    : filterData.slice(2).join(' ');
                                            if (filterDesign && filterValue)
                                                return (
                                                    <li>
                                                        {window.Dictionary.getTranslation(filterDesign.Label)} {filterData[1] === 'LIKE' ? '~' : filterData[1]}{' '}
                                                        {filterDesign.Type === 'bool' ? (filterValue === '1' ? window.Dictionary.getTranslation(1651) : window.Dictionary.getTranslation(1652)) : filterValue}
                                                        <button
                                                            disabled={filterDesign.ReadOnly}
                                                            type="button"
                                                            className="no-styling"
                                                            onClick={e => {
                                                                removeFilter(filterData, filterDesign, filterDesign.ReadOnly);
                                                            }}>
                                                            <i className="fas fa-close"></i>
                                                        </button>
                                                    </li>
                                                );
                                            else return <></>;
                                        })}

                                    {props.filterQuery && (
                                        <button onClick={removeAllFilters}>
                                            <i className="icon-delete"></i>
                                        </button>
                                    )}
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className="mfx-grid-container">
                        {props.filters?.Data?.map((data: any) => {
                            var customValue = data.SpecialFilter ? getSpecialFilterValue(data.FilterName ?? data.Key) : filterValues && (filterValues[data.Label]?.value ?? '');
                            switch (data.Type) {
                                case 'bool':
                                    return (
                                        <div className="mfx-filter-col checkbox">
                                            <YesOrNoElement value={customValue === true ? 1 : 0 ?? 0} designData={data} onChangeHandler={e => (data.SpecialFilter ? setSpecialFilterValue(data.FilterName ?? data.Key, e.newValue, data) : changeFilterValuesHandler(e))} />
                                        </div>
                                    );
                                case 'boolnon':
                                    return (
                                        <div className="mfx-filter-col checkbox">
                                            <YesOrNoElement noNeutral={true} value={customValue === true ? 1 : 0 ?? 0} designData={data} onChangeHandler={e => (data.SpecialFilter ? setSpecialFilterValue(data.FilterName ?? data.Key, e.newValue, data) : changeFilterValuesHandler(e))} />
                                        </div>
                                    );
                                case 'dropdown':
                                    return (
                                        <div className="mfx-filter-col dropdown">
                                            <Dropdown designData={data} isFilter={true} customerData={customValue} onChangeHandler={e => (data.SpecialFilter ? setSpecialFilterValue(data.FilterName ?? data.Key, e.newValue, data) : changeFilterValuesHandler(e))} onEnterPressed={filterTable} />
                                        </div>
                                    );
                                default:
                                    return (
                                        <div className="mfx-filter-col input">
                                            <InputElement
                                                isFilter={true}
                                                designData={{ ...data }}
                                                customerData={customValue}
                                                onChangeHandler={e => (data.SpecialFilter ? setSpecialFilterValue(data.FilterName ?? data.Key, e.newValue, data) : changeFilterValuesHandler(e))}
                                                onEnterPressed={filterTable}
                                                ignoreDefaultValue={true}
                                            />
                                        </div>
                                    );
                            }
                        })}
                    </div>
                    <div className="mfx-grid-container">
                        <button className="mfx-button right" onClick={() => filterTable()}>
                            <span className="icon-filter"></span>
                            {window.Dictionary.getTranslation(14938)}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}
